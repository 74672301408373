


















import { Component, Prop, Vue } from 'vue-property-decorator';
import barChart from '@/components/Argon/Charts/BarChart';
import { barChartInterface } from '@/util/interfacesHelper';
import { chartData, chartDataset } from '@/store/interfaces/generic';

type data = {
  options: {
    tooltips: {
      callbacks: {
        label: (tooltipItem: chartDataset, data: chartData) => string
      }
    }
    responsive: boolean
    maintainAspectRatio: boolean
    scales: {
      xAxes: {
        ticks: {
          maxRotation: number,
          minRotation: number,
        },
        gridLines: {
          offsetGridLines: boolean,
        }
      }[],
      yAxes: {
        ticks: {
          beginAtZero: boolean,
        }
      }[]
    }
  }
}

type chartDataCustom = {
  labels: Array<string>,
      dataset: {
        barPercentage: number,
        minBarThickness: number
        maxBarThickness: number,
      },
      datasets: [{
        barPercentage: number,
        minBarThickness: number, // number (pixels) or 'flex'
        maxBarThickness: number,
        label: string,
        data: Array<string>,
        backgroundColor: string | undefined,
        borderWidth: 1,
      }],
}

@Component({
  name: 'CustomStatChartBar',
  components: {
    barChart,
  },
})

export default class CustomStatCardChart extends Vue {
  data(): data {
    return {
      options: {
        tooltips: {
          callbacks: {
            label: function(
              tooltipItem: chartDataset,
              data: chartData
            ) {
              let label = data.datasets[tooltipItem.datasetIndex].label;
              label += ': ';
              const formatter = new Intl.NumberFormat('en-US',
                { style: 'currency', currency: 'USD', }
              );
              label += formatter.format(tooltipItem.yLabel);
              return label;
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                maxRotation: 90,
                minRotation: 60,
              },
              gridLines: {
                offsetGridLines: true,
              },
            }
          ],
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      },
    };
  }

  @Prop({ required: true, type: String, }) title?: string
  @Prop({ required: false, type: String, default: '#FF575A', })
  color?: string

  @Prop({ required: true, type: Object, })
  dataset!: barChartInterface;

  chartData(): chartDataCustom {
    return {
      labels: this.dataset.label,
      dataset: {
        barPercentage: 0.8,
        minBarThickness: 1, // number (pixels) or 'flex'
        maxBarThickness: 90,
      },
      datasets: [{
        barPercentage: 0.8,
        minBarThickness: 1, // number (pixels) or 'flex'
        maxBarThickness: 90,
        label: 'Ventas totales',
        data: this.dataset.data,
        backgroundColor: this.color,
        borderWidth: 1,
      }],
    };
  }
}

