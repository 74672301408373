



















































































import { Component, Ref, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import contactModal from '@/components/Staff/addTeipBizUser.vue';
import companyUser from '@/store/types/companyUser';
import SweetAlert from '@/util/classes/SweetAlert';

import { AxiosError } from 'axios';
import { TeipNotifications } from '@/util/teipNotifications';
import { CompanyUserDataPagination, CompanyUserParams } from '@/store/interfaces/companyUser';
import permissionGroup from '@/store/types/permissionGroup';
import authType from '@/store/types/auth';
import ReloadComponentMixin from '@/mixins/ReloadComponentMixin';
import { mixins } from 'vue-class-component';
import { VuexFunction } from '@/ts/axios';
import { propsToSearch, tableColumns } from '@/ts/tables';
import { TableRef } from '@/store/interfaces/generic';

const notification = new TeipNotifications();

type userData = {
  permission_group_id: string
  user: unknown
  user_id?: string
}

type data = {
  viewDetails: boolean
  propsToSearch: propsToSearch
  tableColumns: tableColumns
  contact_modal: boolean,
  contactSelected: unknown,
  rowSelected: unknown,
  errors: unknown,
  success: boolean,
  saveIsSuccess: boolean,
  deleteContactConfirm: boolean,
}

@Component({
  name: 'companyUsers',
  components: {
    contactModal,
  },
})
export default class companyUsers extends mixins(ReloadComponentMixin) {
  data(): data {
    return {
      viewDetails: false,
      propsToSearch: ['email'],
      tableColumns: [
        {
          prop: 'name',
          label: 'Nombre',
          minWidth: 100,
          sortable: true,
        },
        {
          prop: 'user.email',
          label: 'Email',
          minWidth: 180,
          sortable: true,
        }
      ],
      contact_modal: false,
      contactSelected: {},
      rowSelected: {},
      errors: {},
      success: false,
      saveIsSuccess: false,
      deleteContactConfirm: false,
    };
  }

  // METHODS
  rowSelect(row: CompanyUserParams): void {
    this.$data.rowSelected = row;
  }

  editUser(): void {
    this.$data.contactSelected = { ...this.$data.rowSelected, };
    this.$data.contact_modal = true;
  }

  newUser(): void {
    this.$data.contactSelected = {};
    this.$data.contact_modal = true;
  }

  triggerContactInfo(val: {update: boolean, user: CompanyUserParams}): void {
    if (val.update) {
      this.updateContact(val.user);
    } else {
      this.createContact(val.user);
    }
  }

  updateContact(user: CompanyUserParams): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...user, };
    delete data.id;
    delete data.user;

    const dataToSend = {
      user_id: this.$data.contactSelected.id,
      data,
    };
    this.updateUser(dataToSend)
      .then(() => {
        notification.success('Usuario actualizado correctamente');
        this.$data.contact_modal = false;
        this.refCompanyUserList.debouncedFetch();
      })
      .catch((err: AxiosError) => {
        this.setServerErrors(err);
      })
      .finally(() => {
        this.$data.success = false;
      });
  }

  mounted(): void {
    this.permissionGroupList();
  }

  @Watch('companySelected')
  onCompanySelectedChanged(val: string, oldVal: string): void {
    (val !== oldVal) &&
      this.refCompanyUserList.fetchData();
  }

  createContact(user: CompanyUserParams): void {
    const userData: userData = {
      permission_group_id: user.permission_group_id,
      user: user.user,
    };
    if (user.id) {
      userData.user_id = user.id;
      delete userData.user;
    }

    this.saveUser(userData)
      .then(() => {
        notification.success('Usuario agregado correctamente');
        this.$data.contact_modal = false;
        this.$data.saveIsSuccess = true;
        this.refCompanyUserList.debouncedFetch();
      })
      .catch((err: AxiosError) => {
        this.$data.saveIsSuccess = false;
        this.setServerErrors(err);
      });
  }

  setServerErrors(err: AxiosError): void {
    const notificacion = new TeipNotifications();
    if (err.response?.status === 422) {
      this.$data.errors = err.response.data.error;
    } else {
      notificacion.warning('Algo ha salido mal');
    }
  }

  async resendEmail(): Promise<void> {
    const companyUser = this.$data.rowSelected;
    const { id, user: { confirmed, }, } = companyUser;
    const notification = new TeipNotifications();
    const swal = new SweetAlert();
    swal
      .askingSweetAlert({
        title: 'Reenviar invitación',
        text: '¿Seguro que desea reenviar la invitación al Usuario?',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, reeenviar',
        alertType: 'warning',
      })
      .then((res) => {
        const basePath = `companies/company/company_users/${id}`;
        const url = confirmed ? `${basePath}/welcome` : `${basePath}/invite`;

        res && (
          this.$http.get(url)
            .then(() => {
              notification.success('Reenvio de invitación satisfactorio');
            })
            .catch(() => {
              notification.warning(
                'No se ha podido reenviar la invitación :('
              );
            })
        );
      });
  }

  async deleteAction(): Promise<void> {
    const companyUser = this.$data.rowSelected;
    const notification = new TeipNotifications();
    const swal = new SweetAlert();
    swal
      .askingSweetAlert({
        title: 'Eliminar Usuario',
        text: '¿Seguro que desea eliminar el Usuario?',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí, eliminar',
        alertType: 'warning',
      })
      .then((res) => {
        res && (
          this.deletedCompanyUser(companyUser.id)
            .then(() => {
              notification.success('Usuario eliminado');
              this.refCompanyUserList.debouncedFetch();
            })
            .catch(
              () => {
                notification.warning('Ha ocurrido un error al eliminar :(');
              }
            )
        );
      });
    this.refCompanyUserList.fetchData();
  }

  // REF
  @Ref('refCompanyUserList') readonly refCompanyUserList!: TableRef;
  // VUEX
  @Action(`companyUserModule/${companyUser.actions.COMPANY_USERS_LIST}`)
  CompanyUserList!: VuexFunction;

  @Action(`companyUserModule/${companyUser.actions.DELETE_COMPANY_USER}`)
  deletedCompanyUser!: VuexFunction;

  @Action(`companyUserModule/${companyUser.actions.SAVE_COMPANY_USER}`)
  saveUser!: VuexFunction;

  @Action(`companyUserModule/${companyUser.actions.UPDATE_COMPANY_USER}`)
  updateUser!: VuexFunction;

  @Getter(`companyUserModule/${companyUser.getters.GET_COMPANY_USERS}`)
  getCompanyUserList?: CompanyUserDataPagination;

  @Action(`permissionGroupModule/${permissionGroup.actions.PERMISSION_GROUP_LIST}`)
  permissionGroupList!: VuexFunction;

  @Getter(`authModule/${authType.getters.GET_COMPANY}`)
  companySelected?: string | undefined
}
