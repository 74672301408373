































import Vue from 'vue';
import { TokenColorDarkText80 } from 'glue-design';
import tOptions from './tOptions.vue';
import TMobile from './tMobile.vue';

export default Vue.extend({
  components: { tOptions, TMobile, },
  name: 'NavBar',
  data() {
    return {
      TokenColorDarkText80,
      showModal: false,
      isUserScrolling: false,
    };
  },
  computed: {
    backgroundColor(): string {
      return this.showModal || this.isUserScrolling
        ? 'bg-token-dark-base-background md:bg-transparent'
        : 'bg-transparent';
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Any code to be executed when the window is scrolled
      this.isUserScrolling = window.scrollY > 0;
    },
    showMenu() {
      this.showModal = !this.showModal;
    },
  },
});
