







import { status, statusColor } from '@/util/transformString/statusDecorator';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Status extends Vue {
  @Prop({
    required: true,
    type: String,
    validator(value: string) {
      const allowed = [
        'pending',
        'success',
        'failure',
        'rejected',
        'cancelled',
        'expired',
        'completed',
        'complete'
      ].includes(value);

      if (!allowed) {
        console.warn(
          'prop status must be pending or success or failure or rejected or cancelled or expired',
          `recieve: ${value}`
        );
      }

      return allowed;
    },
  })
  status!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  })
  classes!: string;

  get color(): string {
    return statusColor(this.status);
  }

  get statusTranslate(): string {
    return status(this.status);
  }
}
