











import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import SidebarModal from '@/components/Reusable/SidebarModal.vue';
import CommerceBranchesSelect from '@/components/RemotePayment/CommerceBranchesSelect.vue';
import LinkInfo from '@/components/RemotePayment/PaymentsLink/LinkInfo.vue';

@Component({
  components: { SidebarModal, CommerceBranchesSelect, LinkInfo, },
})
export default class PaymentLink extends Vue {
  @PropSync('showModal', {
    required: true,
    type: Boolean,
  })
  showModalSync!: boolean;

  branchId = '';
  commerceId = '';

  @Watch('commerceId', { deep: true, })
  onCommerceIdChange(val: string, oldValue: string): void {
    if (oldValue.length > 0 && val.length === 0) {
      this.branchId = '';
    }
  }
}
