

















import NavBar from '@/components/Login/navBar.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'AuthenticationLayout',
  components: { NavBar, },
});
