











import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { Settings } from 'luxon';
import Vue from 'vue';
Settings.defaultLocale = 'es';

export default Vue.extend({
  name: 'DateTime',
  components: {
    Datetime,
  },
  props: {
    minDatetime: {
      required: false,
      type: String,
    },
    maxDatetime: {
      required: false,
      type: String,
    },
    value: {
      required: true,
      type: String,
    },
  },
  computed: {
    vModel: {
      get(): string {
        return this.value;
      },
      set(val: string): void {
        if (val) this.$emit('input', val);
      },
    },
  },
});
