









































import { responseDataPagination } from '@/store/interfaces/generic';
import { http } from '@/util/HTTP';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { tPaymentLinks } from '@/store/interfaces/paymentsLink';
import { TeipNotifications } from '@/util/teipNotifications';

@Component
export default class PaymentsLinkList extends Vue {
  @Prop({ type: String, required: true, }) branchId!: string;
  getLinkstHasBeenCalled = false;

  paymentLinks: responseDataPagination<tPaymentLinks> = {
    data: [],
    pagination: {},
  };

  async getLinks(): Promise<void> {
    const { data, } = await http.get<responseDataPagination<tPaymentLinks>>(
      'companies/company/branches/payment_links?per_page=100'
    );
    this.paymentLinks = data;
  }

  get getNameOfBranch(): string {
    const name = this.paymentLinks.data.find(d => d.id === this.branchId)?.name;
    return name || '';
  }

  get getLinkOfBranch(): string {
    const url = this.paymentLinks.data.find(d => d.id === this.branchId)?.url;
    return url || '';
  }

  copyLink(): void {
    const notification = new TeipNotifications();
    const url = this.getLinkOfBranch;

    navigator.clipboard.writeText(url);

    notification.success('Copiado!!', url);
  }

  @Watch('branchId', { deep: true, })
  async onBrancheIdChange(val: string): Promise<void> {
    if (val && !this.getLinkstHasBeenCalled) {
      this.getLinks();
      this.getLinkstHasBeenCalled = true;
    }
  }
}
