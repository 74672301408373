import Vue from 'vue';
import DatesFormat from '@/util/classes/datesFormat';

const dateFormat: DatesFormat = new DatesFormat();

export const moneyCents = (money: number): string => {
  money = money / 100;
  const formatter = new Intl.NumberFormat('en-US',
    { style: 'currency', currency: 'USD', }
  );
  return formatter.format(money);
};

export const money = (money: number): string => {
  const formatter = new Intl.NumberFormat('en-US',
    { style: 'currency', currency: 'USD', }
  );
  return formatter.format(money);
};

Vue.filter('money', money);

Vue.filter('date', function (date: string) {
  return dateFormat.formatDate(date);
});

Vue.filter('dateTime', function (date: string) {
  return dateFormat.formatDateTime(date);
});
Vue.filter('moneyCents', moneyCents);
