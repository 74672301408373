import Swal, {
  SweetAlertIcon,
  SweetAlertResult,
  SweetAlertCustomClass
} from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

declare interface simpleSweetAlertInterface {
  title: string;
  message?: string;
  alertType: SweetAlertIcon;
}
declare interface sweetConfig {
  title: string;
  text: string;
  confirmButtonText: string;
  cancelButtonText: string;
  alertType: SweetAlertIcon;
  customClass?: SweetAlertCustomClass;
}

export default class SweetAlert {
  async simpleSweetAlert (config: simpleSweetAlertInterface): Promise<void> {
    Swal.fire(config.title, config.message, config.alertType);
  }

  async askingSweetAlert (config: sweetConfig): Promise<boolean> {
    let optionSelected = false;

    await Swal.fire({
      title: config.title,
      text: config.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: config.confirmButtonText,
      cancelButtonText: config.cancelButtonText,
      reverseButtons: true,
      customClass: {
        cancelButton: 'btn base-button btn-outline-secondary',
        confirmButton: 'btn base-button float-right btn-primary',
      },
      buttonsStyling: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    })
      .then((result: SweetAlertResult) => {
        optionSelected = !!result.value;
      })
      .catch(() => {});
    return optionSelected;
  }
}
