












































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import GroupTeipSelect from '@/components/Reusable/groupTeipSelect.vue';
import CustomStatCard from '@/components/SalesReport/CustomStatCard.vue';
import CustomStatChartBar from '@/components/Reusable/CustomStatChartBar.vue';
import { selectOptions } from './options';
import { dateParser } from '@/util/classes/dateParser';
import moment from 'moment';
import {
  convertToBarCharData,
  groupBySum,
  summaryStatistics
} from '@/util/classes/groupObject';
import flatPicker from '@/components/Reusable/FlatPicker.vue';
import commerces from '@/store/types/commerces';
import branches from '@/store/types/branches';
import authType from '@/store/types/auth';
import transactions from '@/store/types/transactions';

import { CommerceDataPagination, IdName } from '@/store/interfaces/commerce';
import { Action, Getter } from 'vuex-class';
import { Report, SalesStatistics } from '@/store/interfaces/transactions';
import { ObjectKeys, responseDataPagination } from '@/store/interfaces/generic';
import copyObj from '@/util/copyObj';
import { VuexFunction } from '@/ts/axios';
moment.locale('es');

type topCards = {
  title: string;
  subTitle: string | number;
  subTitleClasses: string;
  hasTooltip?: boolean;
  tooltipLabel?: string;
};

@Component({
  name: 'SalesReportHome',
  components: {
    GroupTeipSelect,
    CustomStatCard,
    CustomStatChartBar,
    flatPicker,
  },
})
export default class SalesReportHome extends Vue {
  get topCards(): topCards[] {
    let percentage = 0;
    const {
      total_cents,
      previous_period_total_cents,
    } = this.$data.statisticsValues;
    const total_cents_cp = parseFloat(total_cents.substring(1));
    const previous_period_total_cents_cp = parseFloat(
      previous_period_total_cents.substring(1)
    );
    let isDecrease = true;
    let percentageLabel = '0.00%';

    if (previous_period_total_cents_cp > 0) {
      if (total_cents_cp > previous_period_total_cents_cp) {
        const increase = total_cents_cp - previous_period_total_cents_cp;
        percentage = (increase / previous_period_total_cents_cp) * 100;
        isDecrease = false;
      } else if (total_cents_cp < previous_period_total_cents_cp) {
        const decrease = previous_period_total_cents_cp - total_cents_cp;
        percentage = (decrease / previous_period_total_cents_cp) * 100;
      }

      const percentageFixed = percentage.toFixed(2);
      const sign = percentageFixed === '0.00' ? '' : isDecrease ? '-' : '+';
      percentageLabel = `${sign}${percentageFixed}%`;
    }
    return [
      {
        title: 'Total ventas',
        subTitle: this.$data.statisticsValues.total_cents ?? 0,
        subTitleClasses: 'text-muted',
      },
      {
        title: 'Crecimiento de ventas',
        subTitle: percentageLabel,
        subTitleClasses: isDecrease ? 'text-red' : 'text-success',
        hasTooltip: true,
        tooltipLabel:
          'Índice de crecimiento de ventas comparado con el período anterior',
      },
      {
        title: 'Promedio Diario',
        subTitle: this.$data.statisticsValues.daily_average_cents ?? 0,
        subTitleClasses: 'text-muted',
      },
      {
        title: 'Ticket Promedio',
        subTitle: this.$data.statisticsValues.ticket_average_cents ?? 0,
        subTitleClasses: 'text-muted',
      }
    ];
  }

  get cards2(): topCards[] {
    return [
      {
        title: 'Número de transacciones',
        subTitle: this.$data.statisticsValues.transactions_count ?? 0,
        subTitleClasses: 'text-muted',
      },
      {
        title: 'Transacciones por día',
        subTitle: this.$data.statisticsValues.daily_transactions_count ?? 0,
        subTitleClasses: 'text-muted',
      },
      {
        title: 'Clientes Únicos',
        subTitle: this.$data.statisticsValues.users_count ?? 0,
        subTitleClasses: 'text-muted',
      }
    ];
  }

  isChangedCompany = false;
  enableRange = false;
  options = selectOptions;
  range = '';
  dateRange = '';
  from = '';
  to = '';
  commerceId = '';
  branchId = '';
  chart = {};
  weeklyChart = {};
  cleanInputs = false;
  config = {
    mode: 'range',
    wrap: true, // set wrap to true only when using 'input-group'
    altFormat: 'M j, Y',
    altInput: true,
    dateFormat: 'Y-m-d',
  };

  periodRange = '1';
  periodRangeOptions = [
    {
      id: '1',
      name: 'Diario',
      disabled: false,
    },
    {
      id: '2',
      name: 'Semanal',
      disabled: true,
    },
    {
      id: '3',
      name: 'Mensual',
      disabled: true,
    }
  ];

  queryValues = {
    created_after: moment().format('YYYY-MM-DD'),
    created_before: moment().format('YYYY-MM-DD'),
  };

  queryString = '';
  statisticsValues = {
    daily_average_cents: '$ 0',
    daily_transactions_count: 0,
    previous_period_total_cents: '$ 0',
    ticket_average_cents: '$ 0',
    total_cents: '$ 0',
    transactions_count: 1,
    users_count: 0,
  };

  async mounted(): Promise<void> {
    await this.commercesList();
    await this.salesReport({
      queryString: this.$data.queryString,
      isWeekly: false,
    });
    await this.salesStatistics(this.$data.queryString);
    this.$data.statisticsValues = summaryStatistics(this.getStatistics);
    this.$data.chart = convertToBarCharData(this.report, 'date', 'total_cents');
    await this.weeklyChartFn();
    this.$data.isLoading = false;
  }

  @Watch('periodRange')
  onPeriodRangeChange(): void {
    this.printValues();
  }

  @Watch('range')
  onRangeChange(val: string): void {
    this.$data.from = '';
    this.$data.to = '';
    this.$data.dateRange = '';
    this.$data.enableRange = false;
    if (val === 'custom') {
      this.$data.enableRange = true;
      this.$nextTick(() => {
        this.customRangeSelector.fp.fp.open();
      });
    } else {
      const today = moment();
      const [from, to] = dateParser(val, today);
      this.$data.from = from;
      this.$data.to = to;
      this.disabledOptions(from, to);
    }
  }

  @Watch('companySelected')
  async onCompanySelectedChanged(val: string, oldVal: string): Promise<void> {
    if (val !== oldVal) {
      this.$data.isChangedCompany = true;
      this.$data.commerceId = '';
      this.$data.branchId = '';
      this.$data.dateRange = '';
      this.$data.range = 'today';
      this.$data.periodRange = '1';
      await this.commercesList();
      await this.salesReport({
        queryString: this.$data.queryString,
        isWeekly: false,
      });
      await this.salesStatistics(this.$data.queryString);
      this.$data.chart = convertToBarCharData(
        this.report,
        'date',
        'total_cents'
      );
      await this.weeklyChartFn();
      this.printValues();
      this.$data.isChangedCompany = false;
    }
  }

  @Watch('dateRange')
  onChangeDateRange(val: string): void {
    val && this.splitDate(val);
  }

  @Watch('commerceId')
  onCommerceId(val: string): void {
    this.$data.branchId = '';
    this.branchesList({ commerce_id: val, params: 'per_page=100', });
    delete this.$data.queryValues.branch_id;
    if (val) {
      this.$set(this.$data.queryValues, 'commerce_id_eq', val);
    } else {
      this.$set(this.$data.queryValues, 'commerce_id_eq', '');
    }
  }

  @Watch('branchId')
  onChangedBranchId(val: string): void {
    if (val) {
      this.$set(this.$data.queryValues, 'branch_id_eq', val);
    } else {
      this.$set(this.$data.queryValues, 'branch_id_eq', '');
    }
  }

  @Watch('queryValues', { immediate: true, deep: true, })
  onChangedQueryValues(val: ObjectKeys): void {
    const keys = Object.keys(val);
    let query = '';
    keys.forEach(k => {
      if (val[k]) {
        query += `${k}=${val[k]}&`;
      }
    });
    this.$data.queryString = query;
  }

  @Watch('queryString')
  async onChangedQueryString(query: string): Promise<void> {
    if (!this.$data.isChangedCompany) {
      await this.salesReport({ queryString: query, isWeekly: false, });
      await this.salesStatistics(this.$data.queryString);
      this.printValues();
    }
  }

  splitDate(val: string): void {
    const [from, to] = val.split(' a ');
    if (to) {
      this.$data.from = from;
      this.$data.to = to;
      this.disabledOptions(from, to);
    }
  }

  disabledOptions(from: string, to: string): void {
    this.$set(this.$data.queryValues, 'created_after', from);
    this.$set(this.$data.queryValues, 'created_before', to);
    const now = moment(to);
    const end = moment(from);
    var duration = now.diff(end, 'days');
    this.$data.periodRangeOptions.map((pR: ObjectKeys) => {
      pR.disabled = false;
    });
    if (duration >= 120) {
      this.$data.periodRange = '2';
      this.$data.periodRangeOptions[0].disabled = true;
    } else {
      if (now.format('YYYY W') === end.format('YYYY W')) {
        this.$data.periodRange = '1';
        this.$data.periodRangeOptions[1].disabled = true;
      }
      if (now.format('YYYY M') === end.format('YYYY M')) {
        this.$data.periodRange = '1';
        this.$data.periodRangeOptions[2].disabled = true;
      }
    }
  }

  printValues(): void {
    if (this.$data.periodRange === '1') {
      this.$data.chart = convertToBarCharData(
        this.report,
        'date',
        'total_cents'
      );
    } else if (this.$data.periodRange === '2') {
      this.$data.chart = groupBySum(this.report, 'date', 'total_cents', 0);
    } else {
      this.$data.chart = groupBySum(this.report, 'date', 'total_cents', 1);
    }
    this.$data.statisticsValues = summaryStatistics(this.getStatistics);
  }

  async weeklyChartFn(): Promise<void> {
    const today = moment();
    const [from, to] = dateParser('current_week', today);
    await this.salesReport({
      queryString: `created_after=${from}&created_before=${to}`,
      isWeekly: true,
    });
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    const days = copyObj(this.weeklyReport);
    var day = startOfWeek;
    while (day <= endOfWeek) {
      const cloneDay = day.clone();
      const parsedDay = day.format('YYYY-MM-DD').toString();
      const findDay = days.find((d: Report) => d.date === parsedDay);
      if (!findDay) {
        days.push({
          total_cents: 0,
          total_currency: 'USD',
          date: parsedDay,
        });
      }
      day = cloneDay.add(1, 'd');
    }
    days.sort((a: Report, b: Report) => {
      var c = Number(new Date(a.date));
      var d = Number(new Date(b.date));
      return c - d;
    });
    this.$data.weeklyChart = convertToBarCharData(
      days,
      'date',
      'total_cents',
      false,
      'dddd'
    );
  }

  @Action(`commercesModule/${commerces.actions.COMMERCE_LIST}`)
  commercesList!: VuexFunction;

  @Action(`branchesModule/${branches.actions.BRANCHES_LIST}`)
  branchesList!: VuexFunction;

  @Getter(`branchesModule/${branches.getters.GET_BRANCHES_LIST}`)
  branches!: responseDataPagination<IdName>;

  @Getter(`transactionsModule/${transactions.getters.GET_REPORT}`)
  report!: Array<Report>;

  @Getter(`transactionsModule/${transactions.getters.GET_WEEKLY_REPORT}`)
  weeklyReport!: Array<Report>;

  @Action(`transactionsModule/${transactions.actions.SALES_REPORT}`)
  salesReport!: VuexFunction;

  @Getter(`commercesModule/${commerces.getters.GET_COMMERCE_LIST}`)
  commerces!: CommerceDataPagination;

  @Getter(`authModule/${authType.getters.GET_COMPANY}`)
  companySelected?: string | undefined;

  @Action(`transactionsModule/${transactions.actions.SALES_STATISTICS}`)
  salesStatistics!: VuexFunction;

  @Getter(`transactionsModule/${transactions.getters.GET_SALES_STATISTICS}`)
  getStatistics!: SalesStatistics;

  @Ref('customRangeSelector') readonly customRangeSelector!: flatPicker;
}
