




























































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import profile from '@/components/Profile/EditProfile.vue';
import auth from '@/store/types/auth';
import { AxiosResponse } from 'axios';
import unknowUser from '@/assets/images/unknow-user.png';
import { AuthUserParams } from '@/store/interfaces/auth';

@Component({
  name: 'Profile',
  components: {
    profile,
  },
})
class ProfileCurrentUser extends Vue {
  unknowUser: string = unknowUser
  async refetchtUser(): Promise<void> {
    const currentUser: AxiosResponse = await this.$http.get('companies/user');
    this.$store.commit(
      `authModule/${auth.mutations.SET_AUTH_USER}`,
      currentUser.data.data
    );
  }

  @Getter(`authModule/${auth.getters.GET_AUTH_USER}`)
  currenUser!: AuthUserParams;
}

export default ProfileCurrentUser;
