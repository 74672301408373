






















































































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { CommerceSettings } from '@/store/interfaces/commerce';
import currencyCents from '@/util/transformString/currencyCents';
import { tNewPayment } from '@/store/interfaces/remotePayment';
import { PropType } from 'vue';

@Component({
  name: 'FirstStep',
})
export default class FirstStep extends Vue {
  @PropSync('paymentInfo', {
    required: true,
    type: Object as PropType<tNewPayment>,
  })
  paymentInfoSync!: tNewPayment;

  @Prop({ required: true, type: Object, })
  settings!: CommerceSettings;

  get totalAmount(): string {
    const total =
      Number(this.paymentInfoSync.amount) +
      Number(this.paymentInfoSync.teipTip);
    // this.paymentInfoSync.deliveryFee;
    this.paymentInfoSync.totalCents = total;
    return currencyCents(total * 100);
  }
}
