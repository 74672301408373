import authType from '@/store/types/auth';

const hide = (vnode, behaviour) => {
  if (behaviour === 'hide') {
    vnode.elm.style.display = 'none';
  } else if (behaviour === 'disable') {
    vnode.elm.remove();
  }
};
export default {

  inserted: function (el, binding, vnode) {
    const arg = binding.arg;
    const behaviour = binding.modifiers.disable ? 'disable' : 'hide';
    const permissions = vnode.context.$store.getters[`authModule/${authType.getters.PERMISSIONS}`];
    const expression = binding.expression
      .replace(/'/g, '')
      .replace(/"/g, '');
    const hasExpresionWithAction = permissions.some((val) => val === `${arg}_${expression}`);
    const hasExpresionWithoutAction =
      permissions.some(val => val === expression) || permissions.some(val => val === binding.value);
    if (arg && !hasExpresionWithAction) {
      hide(vnode, behaviour);
    } else if (!arg && !hasExpresionWithoutAction) {
      hide(vnode, behaviour);
    }
  },
  update: function (el, binding, vnode) {
    const arg = binding.arg;
    const behaviour = binding.modifiers.disable ? 'disable' : 'hide';
    const permissions = vnode.context.$store.getters[`authModule/${authType.getters.PERMISSIONS}`];
    const expression = binding.expression
      .replace(/'/g, '')
      .replace(/"/g, '');
    const hasExpresionWithAction = permissions.some((val) => val === `${arg}_${expression}`);
    const hasExpresionWithoutAction =
      permissions.some(val => val === expression) || permissions.some(val => val === binding.value);
    if (arg && !hasExpresionWithAction) {
      hide(vnode, behaviour);
    } else if (!arg && !hasExpresionWithoutAction) {
      hide(vnode, behaviour);
    } else {
      vnode.elm.style.display = 'block';
    }
  },
};
