export function status (status: string): string {
  const statusParser: { [key: string]: string } = {
    pending: 'Pendiente',
    success: 'Completada',
    completed: 'Completada',
    complete: 'Completada',
    failure: 'Fallo',
    rejected: 'Rechazada',
    canceled: 'Cancelada',
    cancelled: 'Cancelada',
    expired: 'Expirada',
  };
  return statusParser[status] ? statusParser[status] : 'No definido';
}

export function statusColor (status: string): string {
  const statusParserColor: { [key: string]: string } = {
    pending: 'text-info',
    success: 'text-success',
    completed: 'text-success',
    complete: 'text-success',
    failure: 'text-danger',
    rejected: 'text-warning',
    canceled: 'text-danger',
    cancelled: 'text-danger',
    expired: 'text-warning',
  };
  return statusParserColor[status] ? statusParserColor[status] : 'text-info';
}

export function risk (status: string): string {
  const statusParser: { [key: string]: string } = {
    none: 'Ninguno',
    medium: 'Medio',
    low: 'Bajo',
    high: 'Alto',
  };
  return statusParser[status] ? statusParser[status] : 'No definido';
}

export function orderKind (status: string): string {
  const statusParser: { [key: string]: string } = {
    qr: 'Código QR',
    handle: 'teipTag',
    pos: 'mPOS',
    scan2pay: 'Scan2Pay',
    payment_link: 'Enlace de pago',
  };
  return statusParser[status] ? statusParser[status] : 'No definido';
}
