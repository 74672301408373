



































































































import { BaseNav } from '@/components';
import authType from '@/store/types/auth';
import unknowUser from '@/assets/images/unknow-user.png';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AuthUserParams } from '@/store/interfaces/auth';
import { VuexFunction } from '@/ts/axios';
import { allowAny } from '@/store/interfaces/generic';
type data = {
  activeNotifications: boolean;
  showMenu: boolean;
  searchModalVisible: boolean;
  searchQuery: string;
  unknowUser: string;
  defaultSelected: number;
  company: undefined;
};

@Component({
  name: 'DashboardNavbar',
  components: {
    BaseNav,
  },
})
export default class DashboardNavbar extends Vue {
  @Getter(`authModule/${authType.getters.GET_AUTH_USER}`)
  currentUser?: AuthUserParams;

  @Getter(`authModule/${authType.getters.IS_AUTHENTICATED}`)
  isAuth?: boolean;

  @Getter(`authModule/${authType.getters.GET_COMPANY}`)
  companySelected?: string | undefined;

  @Getter(`authModule/${authType.getters.PERMISSIONS}`)
  permission?: Array<string>;

  @Action(`authModule/${authType.actions.SET_COMPANY}`)
  setCompany!: VuexFunction;

  @Action(`authModule/${authType.actions.AUTH_LOGOUT}`)
  actionLogout!: VuexFunction;

  @Action(`authModule/${authType.actions.RE_FETCH_USER_INFO}`)
  refecthInfo!: VuexFunction;

  @Prop({
    required: false,
    type: String,
  })
  type!: string | undefined;

  @Ref('userPermission') readonly userPermission!: allowAny;
  @Ref('userPermissionMobile') readonly userPermissionMobile!: allowAny;
  get routeName(): string {
    const { name, } = this.$route;
    if (name) {
      return this.capitalizeFirstLetter(name);
    }
    return '';
  }

  data(): data {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      unknowUser,
      defaultSelected: 0,
      company: undefined,
    };
  }

  capitalizeFirstLetter(val: string): string {
    return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
  }

  toggleNotificationDropDown(): void {
    this.$data.activeNotifications = !this.$data.activeNotifications;
  }

  closeDropDown(): void {
    this.$data.activeNotifications = false;
  }

  toggleSidebar(): void {
    this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
  }

  hideSidebar(): void {
    this.$sidebar.displaySidebar(false);
  }

  async logout(): Promise<void> {
    await this.actionLogout();
    this.$router.push({
      name: 'Login',
    });
  }

  myProfile(): void {
    this.$router.push({
      name: 'Profile',
    });
  }

  config(): void {
    this.$router.push({
      name: 'ProfileConfiguration',
    });
  }

  mounted(): void {
    if (this.$route.query.hasNewCompany === '1') {
      this.refecthInfo();
    }
    if (this.currentUser?.companies && this.currentUser?.companies.length > 0) {
      this.$data.company = this.companySelected;
      this.userPermission.$data.select = this.companySelected;
      this.userPermissionMobile.$data.select = this.companySelected;
    }
  }

  @Watch('company')
  onCompanyChanged(val: string): void {
    if (val) {
      this.setCompany(val);
    }
  }
}
