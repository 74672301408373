var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{key:("navBar" + _vm.counter),attrs:{"logo":_vm.logo},on:{"isOpenSidebar":_vm.isOpenSidebar},scopedSlots:_vm._u([{key:"links-after",fn:function(){return [_c('small',{ref:"version",staticClass:"version"},[_vm._v(" "+_vm._s(_vm.showVersion ? ("Versión: " + _vm.version) : _vm.version)+" ")])]},proxy:true}])},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Ventas',
          icon: 'ni ni-money-coins text-primary'
        }}},[_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:('index_company_payment_requests'),expression:"'index_company_payment_requests'"}],attrs:{"link":{
            name: 'Historial de transacciones',
            path: '/transaction-history'
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:('index_company_sales_summaries'),expression:"'index_company_sales_summaries'"}],attrs:{"link":{
            name: 'Resumen de ventas',
            path: '/sales-report'
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:('index_company_orders'),expression:"'index_company_orders'"}],attrs:{"link":{
            name: 'Cobros a distancia',
            path: '/remote-payment'
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:('index_company_order_templates'),expression:"'index_company_order_templates'"}],attrs:{"link":{
            name: 'Pagos dinámicos',
            path: '/dinamic-payments'
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:('scan_to_pay_company_payment_requests'),expression:"'scan_to_pay_company_payment_requests'"}],attrs:{"link":{
            name: 'Escanéa para pagar',
            path: '/scan-to-pay'
          }}})],1),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:('index_company_company_users'),expression:"'index_company_company_users'"}],attrs:{"link":{
          name: 'Gestion de staff',
          icon: 'ni ni-badge text-blue',
          path: '/staff'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Soporte',
          icon: 'fa fa-headset',
          path: '/support'
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }