































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import profileType from '@/store/types/profile';
import authType from '@/store/types/auth';
import rootType from '@/store/types/root';
import { TeipNotifications } from '@/util/teipNotifications';
import copyObj from '@/util/copyObj';
import { UserCompanyParams } from '@/store/interfaces/userCompany';
import { nullToString } from '@/util/transformString/nullValidation';
import { VuexFunction } from '@/ts/axios';

type data = {
  editUser: {
    profile: {
      first_name: string,
      last_name: string,
    }
  }
  edit: boolean,
}

@Component({
  name: 'EditPropfile',
  components: {},
})
class ProfileStaffComponent extends Vue {
  data(): data {
    return {
      editUser: {
        profile: {
          first_name: '',
          last_name: '',
        },
      },
      edit: false,
    };
  }

  async updateProfile(): Promise<void> {
    const notification = new TeipNotifications();
    const newData = this.$data.editUser;
    delete newData.permission_groups;
    const dataToSend = {
      data: newData,
    };
    this.updateUserStaff(dataToSend)
      .then(() => {
        notification.success('Perfil actualizado correctamente');
        this.$data.edit = false;
        this.$emit('refetchtUser', true);
      })
      .catch(() => {
        notification.warning('No hemos podido actualizar el perfil :(');
      });
  }

  nullToString(val: null | string): string {
    return nullToString(val);
  }

  @Watch('edit', { immediate: true, deep: true, })
  onEditChange (): void {
    this.$data.editUser = copyObj(this.currentUser);
  }

  @Action(`profileModule/${profileType.actions.UPDATE_USER_COMPANY}`)
  updateUserStaff!: VuexFunction;

  @Getter(`authModule/${authType.getters.GET_AUTH_USER}`)
  currentUser!: UserCompanyParams;

  @Getter(rootType.getters.GET_START_PROCESS) getProcess!: boolean;
}
export default ProfileStaffComponent;
