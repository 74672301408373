









































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import transactions from '@/store/types/transactions';
import transactionInfo from '@/components/RemotePayment/modals/transactionInfo.vue';
import {
  TransactionDataPagination,
  TransactionParams
} from '@/store/interfaces/transactions';
import CustomFilter from '@/components/Sales/filter.vue';
import DatesFormat from '@/util/classes/datesFormat';
import { orderKind } from '@/util/transformString/statusDecorator';
import commerces from '@/store/types/commerces';
import { CommerceDataPagination, IdName } from '@/store/interfaces/commerce';
import branches from '@/store/types/branches';
import authType from '@/store/types/auth';
import TransactionSidebarDetail from '@/components/Reusable/SidebarModal.vue';
import { TeipNotifications } from '@/util/teipNotifications';
import { isEmpty } from '@/util/transformString/nullValidation';
import moment from 'moment';
import { VuexFunction } from '@/ts/axios';
import { propsToSearch, tableColumns } from '@/ts/tables';
import {
  allowAny,
  responseDataPagination,
  TableRef
} from '@/store/interfaces/generic';
import CreditCard from '@/components/Reusable/CreditCard.vue';
import Status from '@/components/Reusable/Status.vue';

const formatDate = new DatesFormat();

@Component({
  name: 'TransactionsHistory',
  components: {
    CustomFilter,
    TransactionSidebarDetail,
    transactionInfo,
    CreditCard,
    Status,
  },
})
export default class TransactionsHistory extends Vue {
  @Ref('refTransactionsList') readonly refTransactionsList!: TableRef;

  isEmpty = isEmpty;
  propsToSearch: propsToSearch = [];
  query = '';
  interval: allowAny = null;
  updateIsInProcess = false;
  isDownloadReport = false;
  tableColumns: tableColumns = [
    {
      prop: 'commerce_name',
      label: 'Comercio',
      minWidth: 250,
      sortable: true,
    },
    {
      prop: 'branch_name',
      label: 'Sucursal',
      minWidth: 250,
      sortable: true,
    },
    {
      prop: 'user_name',
      label: 'Nombre usuario',
      minWidth: 220,
      sortable: true,
    },
    {
      prop: 'created_at',
      label: 'Fecha y hora',
      minWidth: 220,
      formatter: (row: TransactionParams): string => {
        return `${formatDate.formatDateTime(row.created_at)}`;
      },
    },
    {
      prop: 'total_formatted',
      label: 'Monto Total',
      minWidth: 220,
      sortable: true,
    },
    {
      prop: 'card_network',
      label: 'Métodos de pago',
      minWidth: 220,
      slotName: 'card',
    },
    {
      prop: 'status',
      label: 'Estado',
      minWidth: 220,
      sortable: true,
      slotName: 'status',
    },
    {
      prop: 'order_kind',
      label: 'Tipo',
      minWidth: 220,
      formatter: (row: TransactionParams): string => {
        return `${orderKind(row.order_kind)}`;
      },
    }
  ];

  isLoading = true;
  transaction: TransactionParams = {
    authorization_number: '',
    balance_amount_cents: null,
    balance_amount_currency: null,
    balance_amount_formatted: null,
    biometrics: false,
    branch_name: '',
    card_amount_cents: null,
    card_amount_currency: null,
    card_amount_formatted: null,
    card_last_digits: '',
    card_network: '',
    city_code: '',
    commerce_name: '',
    country_code: '',
    created_at: '',
    description: null,
    device_name: '',
    device_platform: '',
    document_number: '',
    device_vendor: '',
    device_version: '',
    discount_cents: 0,
    discount_currency: '',
    discount_formatted: '',
    distribution: 'not_assigned',
    expires_at: 0,
    fee_cents: 0,
    id: '',
    latitude: '',
    logo: '',
    longitude: '',
    order_kind: 'qr',
    reference_number: '',
    requestable_id: '',
    requestable_type: '',
    response_code: '',
    risk: '',
    state_code: '',
    status: 'pending',
    subtotal_cents: 0,
    subtotal_currency: '',
    subtotal_formatted: '',
    tip_cents: 0,
    tip_currency: '',
    tip_formatted: '',
    total_cents: 0,
    total_currency: '',
    total_formatted: '',
    updated_at: '',
    user_avatar_url: '',
    user_handle: '',
    user_id: '',
    user_name: '',
    expiration_month: null,
    expiration_year: null,
  };

  showTransaction = false;

  isDownloadVoucher = false;
  isSendingEmail = false;

  async updateTransactionList(): Promise<void> {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.updateIsInProcess = true;

      await this.refTransactionsList.fetchData();
      this.refTransactionsList.withSkeleton = true;
      this.updateIsInProcess = false;
    }
    this.interval = setInterval(async () => {
      this.refTransactionsList.withSkeleton = false;
      if (!this.showTransaction) {
        await this.refTransactionsList.fetchData();
      }
      this.refTransactionsList.withSkeleton = true;
    }, 30000);
  }

  destroyed(): void {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  rowSelected(transaction: TransactionParams): void {
    this.transaction = transaction;
    this.showTransaction = true;
  }

  findBy(queryString: string): void {
    this.query = queryString;
  }

  getBranchesByCommerce(commerce_id: string): void {
    this.branchesList({ commerce_id, params: 'per_page=100', });
  }

  async downloadVoucher(id: string): Promise<void> {
    this.isDownloadVoucher = true;
    await this.downloadV({
      id,
      label: `Voucher ${this.transaction.user_name}`,
    });
    this.isDownloadVoucher = false;
  }

  async downloadCsvReport(): Promise<void> {
    this.isDownloadReport = true;
    await this.downloadCsv({
      label: `Report ${moment().format('ll HH [Horas] mm [Minutos]')}.csv`,
      query: `${this.query}&pagination=false`,
    });
    this.isDownloadReport = false;
  }

  resentEmail(id: string): void {
    this.isSendingEmail = true;
    const notification = new TeipNotifications();
    this.resentE(id)
      .then(() => {
        notification.success('Correo reenviado con éxito');
      })
      .catch(() => {
        notification.warning('Algo salió mal :(');
      })
      .finally(() => {
        this.isSendingEmail = false;
      });
  }

  async mounted(): Promise<void> {
    await this.commercesList();
    this.updateTransactionList();
    this.isLoading = false;
  }

  @Watch('companySelected')
  onCompanySelectedChanged(val: string, oldVal: string): void {
    if (val !== oldVal) {
      this.refTransactionsList.fetchData();
    }
  }

  @Action(`transactionsModule/${transactions.actions.TRANSACTIONS_LIST}`)
  transactionsList!: VuexFunction;

  @Action(`transactionsModule/${transactions.actions.DOWNLOAD_VOUCHER}`)
  downloadV!: VuexFunction;

  @Action(`transactionsModule/${transactions.actions.DOWNLOAD_CSV}`)
  downloadCsv!: VuexFunction;

  @Action(`transactionsModule/${transactions.actions.RESENT_EMAIL}`)
  resentE!: VuexFunction;

  @Getter(`transactionsModule/${transactions.getters.GET_TRANSACTIONS_LIST}`)
  getTransactionsList!: TransactionDataPagination;

  @Action(`commercesModule/${commerces.actions.COMMERCE_LIST}`)
  commercesList!: VuexFunction;

  @Getter(`commercesModule/${commerces.getters.GET_COMMERCE_LIST}`)
  getCommercesList!: CommerceDataPagination;

  @Action(`branchesModule/${branches.actions.BRANCHES_LIST}`)
  branchesList!: VuexFunction;

  @Getter(`branchesModule/${branches.getters.GET_BRANCHES_LIST}`)
  getBranchesList!: responseDataPagination<IdName>;

  @Getter(`authModule/${authType.getters.GET_COMPANY}`)
  companySelected?: string | undefined;
}
