

























































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { FadeTransition } from 'vue2-transitions';
import authType from '@/store/types/auth';
import { Action, Getter } from 'vuex-class';
import newPaymentFirstStep from './Steps/FirstStep.vue';
import newPaymentSecondStep from './Steps/SecondStep.vue';
import {
  companyOrderParams,
  companyOrderPaymentParams,
  userPaymentDetail
} from '@/store/interfaces/remotePayment';
import remotePayment from '@/store/types/remotePayment';
import { TeipNotifications } from '@/util/teipNotifications';
import rootType from '@/store/types/root';
import moment, { Moment } from 'moment';
import { createConsumer } from '@rails/actioncable';
import { VuexFunction } from '@/ts/axios';
import { ValidationProvider } from 'vee-validate';
import { transactionInfoToShow, tStatus } from '@/store/interfaces/transactions';
import transactionInfo from '@/components/RemotePayment/modals/transactionInfo.vue';
import transactions from '@/store/types/transactions';
import { allowAny } from '@/store/interfaces/generic';
const notification = new TeipNotifications();

let intervalFn: allowAny;

@Component({
  name: 'PaymentDetail',
  components: {
    newPaymentFirstStep,
    newPaymentSecondStep,
    FadeTransition,
    transactionInfo,
  },
})
export default class PaymentDetail extends Vue {
  @Prop({
    required: true,
    type: [Object],
  })
  paymentInfo!: companyOrderParams;

  @Prop({
    required: true,
    type: String,
  })
  userId!: string;

  resendOrder = false;
  counter: Moment = moment();
  paymentStatus = 'rejected';
  diffTimeValue = '01:00';
  cable: allowAny = null;

  isDownloadVoucher = false;
  isSendingEmail = false;

  get transaction(): transactionInfoToShow {
    return {
      id: this.paymentRequest.id,
      avatar: this.user.profile.avatar_url,
      user: this.user,
      status: this.paymentInfo.status,
      commerce_name: this.paymentInfo.commerce.name,
      branch_name: this.paymentInfo.branch.name,
      fee_cents: this.paymentInfo.payment_requests[0].fee_cents,
      subtotal_cents: this.paymentInfo.payment_requests[0].subtotal_cents,
      discount_cents: this.paymentInfo.payment_requests[0].discount_cents,
      tip_cents: this.paymentInfo.tip_cents,
      created_at: this.paymentInfo.created_at,
      description: this.paymentInfo.description || '',
      distribution: this.paymentInfo.payment_requests[0].distribution,
      balance_amount_formatted: this.paymentInfo.payment_requests[0].balance_amount_formatted,
      card_amount_formatted: this.paymentInfo.payment_requests[0].card_amount_formatted,
    };
  }

  get showResendOrderButton(): boolean {
    return this.requestStatus === 'pending' || this.requestStatus === 'failure';
  }

  get paymentRequest(): companyOrderPaymentParams {
    return this.paymentInfo.payment_requests[0];
  }

  get user(): userPaymentDetail {
    return this.paymentRequest.user;
  }

  get requestStatus(): tStatus {
    return this.paymentRequest.status;
  }

  resentEmail(id: string): void {
    this.isSendingEmail = true;
    const notification = new TeipNotifications();
    this.resentE(id)
      .then(() => {
        notification.success('Correo reenviado con éxito');
      })
      .catch(() => {
        notification.warning('Algo salió mal :(');
      })
      .finally(() => {
        this.isSendingEmail = false;
      });
  }

  async downloadVoucher(id: string): Promise<void> {
    this.isDownloadVoucher = true;
    await this.downloadV({
      id,
      label: `Voucher ${this.user.email}`,
    })
      .catch(() => {
        const teipNotifications = new TeipNotifications();
        teipNotifications.warning('Algo salió mal :(');
      })
      .finally(() => {
        this.isDownloadVoucher = false;
      });
  }

  // Ignore this line for a while to avoid test of anycable
  /* istanbul ignore next */
  mounted(): void {
    this.cable = createConsumer(process.env.VUE_APP_WS_HOST);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctx = this;
    this.cable.subscriptions.create(
      {
        channel: 'Companies::PaymentRequestChannel',
        current_user_id: ctx.userId,
      },
      {
        received(): void {
          ctx.fetchPayment(ctx.paymentInfo.id);
        },
      }
    );
  }

  beforeDestroy(): void {
    this.clearDetails();
    this.cable.subscriptions.consumer.disconnect();
  }

  clearDetails(): void {
    clearInterval(intervalFn);
    this.resendOrder = false;
    this.counter = moment().add(1, 'minutes');
    this.diffTimeValue = '01:00';
  }

  async cancel(): Promise<void> {
    try {
      await this.cancelPayment(this.paymentInfo);
      this.$emit('reFetch');
      notification.success(
        'La orden ha sido cancelada exitosamente',
        '',
        'ni ni-bell-55'
      );
    } catch (_) {
      notification.warning('Algo ha salido mal :(');
    }
  }

  async retry(): Promise<void> {
    try {
      await this.retryPayment(this.paymentInfo);
      notification.success(
        'La orden ha sido reenviada exitosamente',
        '',
        'ni ni-bell-55'
      );
      this.resendOrder = true;
      this.counter = moment().add(1, 'minutes');
      this.diffTimeValue = '01:00';
      this.diffTimeInterval();
    } catch (_) {
      notification.warning('Algo ha salido mal :(');
    }
  }

  diffTimeInterval(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctx = this;
    // Ignore the function used on setInterval
    function diffTimeMinutes(): void {
      const requestedDate = ctx.$data.counter;
      const current: allowAny = moment();
      const countdown = moment(requestedDate - current);
      const minutes = countdown.format('mm');
      const seconds = countdown.format('ss');
      ctx.$data.resendOrder = requestedDate.diff(current, 'seconds') > 0;
      ctx.$data.diffTimeValue = `${minutes}:${seconds}`;
    }
    intervalFn = setInterval(diffTimeMinutes, 500);
  }

  @Watch('paymentInfo')
  onPaymentInfoChange(): void {
    this.clearDetails();
  }

  @Ref('formNewPayment') readonly formNewPayment!: InstanceType<
    typeof ValidationProvider
  >;

  @Getter(rootType.getters.GET_START_PROCESS) getProcess!: boolean;

  @Getter(`authModule/${authType.getters.GET_COMPANY}`)
  companySelected?: string | undefined;

  @Action(`remotePaymentModule/${remotePayment.actions.CANCEL_PAYMENT}`)
  cancelPayment!: VuexFunction;

  @Action(`remotePaymentModule/${remotePayment.actions.RETRY_PAYMENT}`)
  retryPayment!: VuexFunction;

  @Action(`remotePaymentModule/${remotePayment.actions.REMOTE_PAYMENT_SINGLE}`)
  fetchPayment!: VuexFunction;

  @Action(`transactionsModule/${transactions.actions.DOWNLOAD_VOUCHER}`)
  downloadV!: VuexFunction;

  @Action(`transactionsModule/${transactions.actions.RESENT_EMAIL}`)
  resentE!: VuexFunction;
}
