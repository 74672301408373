





























import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import authType from '@/store/types/auth';
import { TeipNotifications } from '@/util/teipNotifications';
import { VuexFunction } from '@/ts/axios';
import backToLogin from '@/components/Reusable/backToLogin.vue';

@Component({
  components: {
    backToLogin,
  },
})
export default class SendUnlockInstructions extends Vue {
  user = {
    email: '',
  };

  loading = false;

  async mounted(): Promise<void> {
    if (this.$route.params.id) {
      await this.autoUnlockAccount();
    }
  }

  async autoUnlockAccount(): Promise<void> {
    this.loading = true;
    const teipNotification = new TeipNotifications();
    try {
      await this.unlockAccount(this.$route.params.id);
      teipNotification.success('Cuenta desbloqueada!');
      await this.$router.push({
        name: 'Login',
      });
    } catch (err) {
      this.loading = false;
      teipNotification.warning(
        'El código es inválido o está vencido, por favor vuelva a ingresar su correo electrónico. ' +
          'Si el error persiste consulte con el Administrador'
      );
      await this.$router.push({
        name: 'SendUnlockAccountMail',
      });
    }
  }

  async sendInstructions(): Promise<void> {
    this.loading = true;
    const teipNotification = new TeipNotifications();
    try {
      await this.sendUnlockdInstructions(this.user);
      teipNotification.success(
        'Se ha enviado un correo con instrucciones para desbloquear su cuenta'
      );
      await this.$router.push({
        name: 'Login',
      });
    } catch (err) {
      this.loading = false;
      teipNotification.warning('Correo inválido o cuenta ya desbloqueada');
    }
  }

  // VUEX
  @Action(`authModule/${authType.actions.SEND_UNLOCK_INSTRUCTIONS}`)
  sendUnlockdInstructions!: VuexFunction;

  @Action(`authModule/${authType.actions.UNLOCK_ACCOUNT}`)
  unlockAccount!: VuexFunction;
}
