<template>
  <base-header :class="pClass" type>
    <b-row align-v="center" class="py-4">
      <b-col lg="6" cols="7">
        <h6 class="h2 d-inline-block mb-0">
          {{ tabSelected }}
        </h6>
        <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
          <slot name="nav"></slot>
        </nav>
      </b-col>
      <b-col lg="6" cols="5" class="text-right">
        <slot name="button" />
      </b-col>
    </b-row>
  </base-header>
</template>

<script>
export default {
  name: 'HeaderBaseTeip',
  props: {
    tabSelected: {
      type: String,
      required: true,
      description: 'Name tab selected',
    },
    pClass: {
      type: String,
      default: 'pb-6',
    },
  },
};
</script>
