
// Notifications plugin. Used on Notifications page
import Notifications from '@/components/Argon/NotificationPlugin';
// Validation plugin used to validate forms
import { configure, extend } from 'vee-validate';
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/components/Argon/SidebarPlugin';
// element ui language configuration
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import Http from './api';
// vue-bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// asset imports'
import '@/assets/sass/argon.scss';
import '@/assets/nucleo/css/nucleo.css';
import 'element-ui/lib/theme-chalk/index.css';

import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/es.json';
import teipComponents from 'teip-components'
import glueComponents from 'glue-components'

locale.use(lang);

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

extend('password_rules', {
  message: field => `${field} debe contener una mayúscula|minúscula|número y
     carácteres especiales ej (! @ # $ % ^ & *) y ser mayor a 8 carácteres.`,
  validate: value => {
    const strongPassword = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    return strongPassword.test(value);
  },
});

export default {
  install (Vue) {
    Vue.use(teipComponents);
    Vue.use(glueComponents);
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(BootstrapVue);
    Vue.use(Http);
    Vue.use(IconsPlugin);
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
      },
    });
  },
};
