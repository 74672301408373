



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class HeaderModal extends Vue {
  @Prop({
    required: true,
    type: String,
  }) fullName!: string

  @Prop({
    required: true,
    type: String,
  }) handle!: string

  @Prop({
    required: true,
    type: String,
  }) avatar!: string
}
