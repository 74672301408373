/* eslint-disable @typescript-eslint/no-explicit-any */

function nullToString (value: null | string): string {
  if (value === null) return '';
  return value;
}
function isEmpty(value: string | undefined | null): boolean {
  return (value == null || value.length === 0);
}

function objectValueNull (value: Record<string, any>): Record<string, any> {
  const temporal: any = {};
  Object.entries(value).forEach(([v, k]: any) => {
    if (typeof k === 'string') {
      temporal[v] = nullToString(k);
    }
    if (typeof k === 'object') {
      if (k && k.length > 0) {
        temporal[v] = arrayValueNull(k);
      } else if (k) {
        temporal[v] = objectValueNull(k);
      } else {
        temporal[v] = nullToString(k);
      }
    }
  });
  return temporal;
}

function arrayValueNull (array: Array<any>): Array<any> {
  const temporalArray: Array<any> = [];
  array.map(array => {
    temporalArray.push(objectValueNull(array));
  });
  return temporalArray;
}

export {
  arrayValueNull,
  nullToString,
  objectValueNull,
  isEmpty
};
