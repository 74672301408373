



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { navLink } from '@/ts/navegationLinks';
@Component({
  name: 'HeadBaseLink',
})
export default class HeadBaseLink extends Vue {
  @Prop({
    required: true,
    type: [String, Array],
  })
  navLink?: string | navLink;

  isString = false;

  @Watch('navLink', { immediate: true, deep: true, })
  onPagesChange(val: string | string[]): void {
    if (typeof val === 'string') {
      this.isString = true;
    } else {
      this.isString = false;
    }
  }
}
