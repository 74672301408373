





















































import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import permissionGroup from '@/store/types/permissionGroup';
import authType from '@/store/types/auth';
import ReloadComponentMixin from '@/mixins/ReloadComponentMixin';
import { mixins } from 'vue-class-component';
import remotePaymentType from '@/store/types/remotePayment';
import {
  companyOrderParams
} from '@/store/interfaces/remotePayment';
import currencyCents from '@/util/transformString/currencyCents';
import DatesFormat from '@/util/classes/datesFormat';
import { VuexFunction } from '@/ts/axios';
import { propsToSearch, tableColumns } from '@/ts/tables';
import { allowAny, responseDataPagination, TableRef } from '@/store/interfaces/generic';
import Status from '@/components/Reusable/Status.vue';
const date = new DatesFormat();

@Component({
  name: 'remotePaymentList',
  components: {
    Status,
  },
})
export default class remotePaymentList extends mixins(ReloadComponentMixin) {
  @Ref('refPaymentsListTable') readonly refPaymentsListTable!: TableRef;
  @Prop({
    required: true,
    type: Boolean,
  })
  reloadTable!: boolean;

  propsToSearch: propsToSearch = ['email'];

  tableColumns: tableColumns = [
    {
      prop: 'commerce.name',
      label: 'Comercio',
      minWidth: 200,
      sortable: true,
    },
    {
      prop: 'branch.name',
      label: 'Sucursal',
      minWidth: 200,
      sortable: true,
    },
    {
      prop: 'payment_requests[0].user.profile.first_name',
      label: 'Usuario',
      minWidth: 200,
      sortable: true,
      formatter: (row: companyOrderParams): string => {
        const user = row.payment_requests[0].user.profile;
        return `${user.first_name} ${user.last_name}`;
      },
    },
    {
      prop: 'total',
      label: 'Total',
      minWidth: 100,
      sortable: true,
      formatter: (row: companyOrderParams): string => {
        const payment = row.payment_requests[0];
        return currencyCents(
          payment.subtotal_cents + payment.tip_cents - payment.discount_cents
        );
      },
    },
    {
      prop: 'status',
      label: 'Status',
      minWidth: 100,
      sortable: true,
      slotName: 'status',
    },
    {
      prop: 'date',
      label: 'Fecha y hora',
      minWidth: 200,
      sortable: true,
      formatter: (row: companyOrderParams): string => {
        const payment = row.payment_requests[0];
        return date.formatDateTime(payment.created_at);
      },
    }
  ];

  updateIsInProcess = true;
  interval: allowAny = null;

  // METHODS
  fullName(row: companyOrderParams): string {
    const user = row.payment_requests[0].user.profile;
    return `${user.first_name} ${user.last_name}`;
  }

  viewDetail(row: companyOrderParams): void {
    this.$emit('triggerDetail', row);
  }

  @Watch('companySelected')
  onCompanySelectedChanged(val: string): void {
    val && this.refPaymentsListTable.fetchData();
  }

  triggerNew(): void {
    this.$emit('triggerNew', true);
  }

  triggerTipLinkPayment(): void {
    this.$emit('paymentLink', true);
  }

  async mounted(): Promise<void> {
    await this.updateTransactionList();
    this.updateIsInProcess = false;
  }

  @Watch('reloadTable', { deep: true, })
  onReloadTableChanged(): void {
    this.refPaymentsListTable.fetchData();
  }

  async updateTransactionList(): Promise<void> {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.updateIsInProcess = true;

      await this.refPaymentsListTable.fetchData();
      this.updateIsInProcess = false;

      this.refPaymentsListTable.withSkeleton = true;
    }

    this.interval = setInterval(async () => {
      this.refPaymentsListTable.withSkeleton = false;
      await this.refPaymentsListTable.fetchData();
      this.refPaymentsListTable.withSkeleton = true;
    }, 30000);
  }

  destroyed(): void {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  // VUEX
  @Action(
    `remotePaymentModule/${remotePaymentType.actions.REMOTE_PAYMENT_LIST}`
  )
  paymentsList!: VuexFunction;

  @Getter(`remotePaymentModule/${remotePaymentType.getters.GET_PAYMENTS}`)
  getPaymentsList!: responseDataPagination<companyOrderParams>;

  @Action(
    `permissionGroupModule/${permissionGroup.actions.PERMISSION_GROUP_LIST}`
  )
  permissionGroupList!: VuexFunction;

  @Getter(`authModule/${authType.getters.GET_COMPANY}`)
  companySelected?: string | undefined;
}
