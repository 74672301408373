/* eslint-disable no-useless-catch */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';
import remotePaymentType from '@/store/types/remotePayment';
import { http } from '@/util/HTTP';
import { companyOrderParams, RemotePaymentState } from '@/store/interfaces/remotePayment';
import { AxiosError, AxiosResponse } from 'axios';
import { responseCollection } from '../interfaces/generic';

const namespaced = true;
const initialState = (): RemotePaymentState => {
  return {
    remotePayments: {
      data: [],
      pagination: {},
    },
    remotePayment: {
      id: '',
      user_id: '',
      subtotal_cents: 0,
      subtotal_currency: '',
      fee_cents: 0,
      fee_currency: '',
      tip_cents: 0,
      tip_currency: '',
      reference_id: '',
      status: 'pending',
      created_at: '',
      updated_at: '',
      latitude: '',
      longitude: '',
      description: '',
      branch: {
        id: '',
        name: '',
      },
      commerce: {
        id: '',
        name: '',
      },
      payment_requests: [],
    },
  };
};
const state: RemotePaymentState = initialState();
const getters: GetterTree<RemotePaymentState, RootState> = {
  [remotePaymentType.getters.GET_PAYMENTS]: state => {
    return state.remotePayments;
  },
};
const mutations: MutationTree<RemotePaymentState> = {
  [remotePaymentType.mutations.SET_PAYMENTS]: (
    state,
    remotePayments: responseCollection<companyOrderParams>
  ) => {
    state.remotePayments.data = remotePayments.data;
    state.remotePayments.pagination = remotePayments.meta.pagination;
  },
  [remotePaymentType.mutations.SET_PAYMENT]: (
    state,
    remotePayment: companyOrderParams
  ) => {
    state.remotePayment = remotePayment;
  },
};
const actions: ActionTree<RemotePaymentState, RootState> = {
  [remotePaymentType.actions.REMOTE_PAYMENT_LIST]: ({ commit, }, queryParams = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/orders?${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(remotePaymentType.mutations.SET_PAYMENTS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [remotePaymentType.actions.REMOTE_PAYMENT_SINGLE]: async ({ commit, }, id) => {
    try {
      const res = await http
        .get(`companies/company/orders/${id}`);
      commit(remotePaymentType.mutations.SET_PAYMENT, res.data.data);
    } catch (error) {
      throw error;
    }
  },
  [remotePaymentType.actions.SET_ORDER_PAYMENT]: async ({ commit, }, data: companyOrderParams) => {
    commit(remotePaymentType.mutations.SET_PAYMENT, data);
  },
  [remotePaymentType.actions.SEARCH_HANDLE]: async (_, handle) => {
    let data = {
      handle,
    };
    try {
      const handle = await http
        .post('companies/company/customer_profiles/handles', { data, });
      data = handle.data.data;
      const session = await http
        .post('companies/company/customer_profiles/sessions', { data, });
      return session.data.data;
    } catch (e) {
      throw e;
    }
  },
  [remotePaymentType.actions.SUBMIT_PAYMENT]: async (_, data: companyOrderParams) => {
    try {
      const handle = await http
        .post('companies/company/orders', { data, });
      return handle.data.data;
    } catch (e) {
      throw e;
    }
  },
  [remotePaymentType.actions.CANCEL_PAYMENT]: async (_, data: companyOrderParams) => {
    const id = data.id;
    const requestId = data.payment_requests[0].id;
    try {
      await http
        .post(`companies/company/orders/${id}/payment_requests/${requestId}/cancel`);
    } catch (e) {
      throw e;
    }
  },
  [remotePaymentType.actions.RETRY_PAYMENT]: async (_, data: companyOrderParams) => {
    const id = data.id;
    const requestId = data.payment_requests[0].id;
    try {
      await http
        .post(`companies/company/orders/${id}/payment_requests/${requestId}/retry`);
    } catch (e) {
      throw e;
    }
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
