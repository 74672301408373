
import Vue from 'vue';
export class TeipNotifications extends Vue {
  warning (title: string, message = ''): void {
    this.$notify({
      type: 'warning',
      title,
      message,
      iconClass: 'ni ni-bell-55',
      duration: 500,
    });
  }

  success (title: string, message = '', iconClass = 'ni ni-check-bold'): void {
    this.$notify({
      type: 'success',
      title,
      message,
      iconClass,
      duration: 500,
    });
  }

  error (title: string, message = ''): void {
    this.$notify({
      type: 'error',
      title,
      message,
      iconClass: 'ni ni-bell-55',
      duration: 500,
    });
  }
}
