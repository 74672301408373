import Vue from 'vue';
import authModule from '@/store/modules/auth';
import profileModule from '@/store/modules/profile';
import transactionsModule from '@/store/modules/transactions';
import commercesModule from '@/store/modules/commerces';
import branchesModule from '@/store/modules/branches';
import companyUserModule from '@/store/modules/companyUser';
import orderKindModule from '@/store/modules/orderKinds';
import permissionGroupModule from '@/store/modules/permissionGroup';
import globalConfig from '@/store/modules/globalConfig';
import remotePaymentModule from '@/store/modules/remotePayment';
import { RootState } from '@/store/interfaces/rootState';
import RootTypes from '@/store/types/root';
import Vuex, { GetterTree, MutationTree } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Sentry
import { setUser, configureScope } from '@sentry/vue';
import { envEvaluation } from '@/config/sentry/enviromentEvaluation';

Vue.use(Vuex);

const state: RootState = {
  startprocess: false,
};
const mutations: MutationTree<RootState> = {
  [RootTypes.mutations.SET_START_PROCESS]: state => {
    state.startprocess = true;
  },
  [RootTypes.mutations.SET_END_PROCESS]: state => {
    state.startprocess = false;
  },
};
const getters: GetterTree<RootState, RootState> = {
  [RootTypes.getters.GET_START_PROCESS]: state => {
    return state.startprocess;
  },
};
export default new Vuex.Store<RootState>({
  state,
  getters,
  mutations,
  modules: {
    authModule,
    profileModule,
    transactionsModule,
    commercesModule,
    branchesModule,
    companyUserModule,
    permissionGroupModule,
    globalConfig,
    remotePaymentModule,
    orderKindModule,
  },
  plugins: [
    createPersistedState({
      key: 'DC',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reducer (val: any) {
        if (!val.authModule.token) {
          if (envEvaluation()) {
            configureScope(scope => scope.setUser(null));
          }
          return {};
        }
        if (envEvaluation()) {
          setUser({ id: val.authModule.currentUser.id, });
        }
        return val;
      },
    })
  ],
});
