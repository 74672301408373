





















































































































import { Component, Vue, PropSync } from 'vue-property-decorator';
import { CommerceDataPagination } from '@/store/interfaces/commerce';
import noImage from '@/assets/images/unknow-user.png';
import commerces from '@/store/types/commerces';
import authType from '@/store/types/auth';
import remotePaymentType from '@/store/types/remotePayment';
import { Action, Getter } from 'vuex-class';
import {
  RemotePayment,
  sessionPaymentRequest
} from '@/store/interfaces/remotePayment';
import { ZoomCenterTransition } from 'vue2-transitions';
import { isEmpty } from '@/util/transformString/nullValidation';
import { VuexFunction } from '@/ts/axios';

@Component({
  name: 'SecondStep',
  components: {
    ZoomCenterTransition,
  },
})
export default class SecondStep extends Vue {
  userFound = false;
  isSearched = false;
  isSearchingUser = false;
  isEmpty = isEmpty;
  noImage = noImage;
  session: sessionPaymentRequest = {
    id: '',
    code: '',
    device: {
      id: '',
      identifier: '',
      vendor: '',
      system_name: '',
      system_version: '',
      push_token: '',
      device_name: '',
    },
    user: {
      id: '',
      email: '',
      confirmed_email: false,
      created_at: '',
      handle: '',
      profile: {
        id: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        birthdate: '',
        gender: '',
        avatar_url: null,
      },
    },
  };

  clear(): void {
    this.paymentInfoSync.user = '';
    this.isSearched = false;
    this.paymentInfoSync.session = undefined;
    this.userFound = false;
  }

  async searchUserKeyPress(e: Event): Promise<void> {
    e.preventDefault();
    await this.searchUser();
  }

  get fullName(): string {
    const { first_name, last_name, } = this.session.user.profile;
    return `${first_name} ${last_name}`;
  }

  get profileImg(): string {
    const { avatar_url, } = this.session.user.profile;

    return avatar_url || noImage;
  }

  async searchUser(): Promise<void> {
    this.isSearchingUser = true;
    try {
      const session = await this.searchHandle(this.paymentInfoSync.user);
      this.session = (session as unknown) as sessionPaymentRequest;

      this.userFound = true;
      this.isSearched = true;
      this.$emit('session', session);
    } catch (_) {
      this.session.id = '';
      this.userFound = false;
      this.isSearched = true;
    }
    this.isSearchingUser = false;
  }

  goToStep(): void {
    this.$emit('goToStep', 1);
  }

  @PropSync('paymentInfo', { required: true, type: Object, })
  paymentInfoSync!: RemotePayment;

  @Getter(`commercesModule/${commerces.getters.GET_COMMERCE_LIST}`)
  commerces!: CommerceDataPagination;

  @Getter(`authModule/${authType.getters.GET_COMPANY}`)
  companySelected?: string | undefined;

  @Action(`remotePaymentModule/${remotePaymentType.actions.SEARCH_HANDLE}`)
  searchHandle!: VuexFunction;
}
