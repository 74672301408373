











import { Component, Vue } from 'vue-property-decorator';
import logo from '@/assets/images/logo-white.svg';
import { VueConstructor } from 'vue';

@Component({
  name: 'landingLayout',
})
export default class extends Vue {
  data(): {logo: VueConstructor<Vue>} {
    return {
      logo,
    };
  }
}
