export default {
  mutations: {
    SET_TRANSACTIONS_LIST: 'SET_TRANSACTIONS_LIST',
    SET_REPORT: 'SET_REPORT',
    SET_WEEKLY_REPORT: 'SET_WEEKLY_REPORT',
    SET_SALES_STATISTICS: 'SET_SALES_STATISTICS',
    SET_SCAN_TO_PAY: 'SET_SCAN_TO_PAY',
    SET_DINAMIC_PAYMENTS: 'SET_DINAMIC_PAYMENTS',
  },
  getters: {
    GET_TRANSACTIONS_LIST: 'GET_TRANSACTIONS_LIST',
    GET_REPORT: 'GET_REPORT',
    GET_WEEKLY_REPORT: 'GET_WEEKLY_REPORT',
    GET_SALES_STATISTICS: 'GET_SALES_STATISTICS',
    GET_SCAN_TO_PAY: 'GET_SCAN_TO_PAY',
    GET_DINAMIC_PAYMENTS: 'GET_DINAMIC_PAYMENTS',
  },
  actions: {
    TRANSACTIONS_LIST: 'TRANSACTIONS_LIST',
    DOWNLOAD_VOUCHER: 'DOWNLOAD_VOUCHER',
    RESENT_EMAIL: 'RESENT_EMAIL',
    SALES_REPORT: 'SALES_REPORT',
    SALES_STATISTICS: 'SALES_STATISTICS',
    DOWNLOAD_CSV: 'DOWNLOAD_CSV',
    SCAN_TO_PAY: 'SCAN_TO_PAY',
    DINAMIC_PAYMENTS: 'DINAMIC_PAYMENTS',
    SAVE_DINAMIC_PAYMENTS: 'SAVE_DINAMIC_PAYMENTS',
    UPDATE_DINAMIC_PAYMENTS: 'UPDATE_DINAMIC_PAYMENTS',
  },
};
