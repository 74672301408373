/* eslint-disable @typescript-eslint/no-explicit-any */
import { ObjectKeys } from '@/store/interfaces/generic';
import { barChartInterface } from '../interfacesHelper';
import moment from 'moment-timezone';
import { SalesStatistics } from '@/store/interfaces/transactions';
import currencyCents from '@/util/transformString/currencyCents';
moment.locale('es');
moment.updateLocale('es', {
  monthsShort: [
    'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN',
    'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'
  ],
  weekdays: [
    'DOMINGO', 'LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO'
  ],
});

const FORMAT_FULL = 'DD.MMM.YYYY';
const FORMAT_DAY_MONTH = 'DD.MMM';
const FORMAT_MONTH_YEAR = 'MMM.YYYY';
enum weeklyMonthly { isWeekly, isMonthly }
export const groupBySum = (
  _array: Array<any>,
  key: string,
  keySum: string,
  isWeeklyOrMonthly: weeklyMonthly
): barChartInterface => {
  const result: Array<any> = [];
  _array.reduce(function (r: ObjectKeys, element: ObjectKeys) {
    let keyFormated: string = moment(element[key]).format(FORMAT_MONTH_YEAR);
    if (isWeeklyOrMonthly === 0) {
      const firstDayOfWeek = moment(element[key]).startOf('week').format(FORMAT_DAY_MONTH);
      const lastDayOfWeek = moment(element[key]).endOf('week').format(FORMAT_DAY_MONTH);
      keyFormated = `${firstDayOfWeek} - ${lastDayOfWeek}`;
    }
    if (!r[keyFormated]) {
      r[keyFormated] = { [key]: keyFormated, [keySum]: 0, };
      result.push(r[keyFormated]);
    }
    r[keyFormated][keySum] += element[keySum];
    return r;
  }, {}
  );
  return convertToBarCharData(result, key, keySum, true);
};

export const convertToBarCharData = (
  _array: Array<any>,
  key: string,
  keySum: string,
  isWeeklyOrMonthly = false,
  customFormat = ''
): barChartInterface => {
  const label: Array<string> = [];
  const data: Array<string> = [];
  _array.forEach(r => {
    if (isWeeklyOrMonthly) {
      label.push(r[key]);
    } else {
      if (customFormat !== '') {
        label.push(moment(r[key], 'YYYY-MM-DD').format(customFormat));
      } else {
        label.push(moment(r[key], 'YYYY-MM-DD').format(FORMAT_FULL));
      }
    }
    data.push((parseInt(r[keySum]) / 100).toString());
  });
  return {
    label,
    data,
  };
};

export const summaryStatistics = (values: SalesStatistics): ObjectKeys => {
  const keys: Array<string> = [
    'daily_average_cents',
    'daily_transactions_count',
    'previous_period_total_cents',
    'ticket_average_cents',
    'total_cents',
    'transactions_count',
    'users_count'
  ];
  const result: ObjectKeys = {};
  keys.forEach(k => {
    const isCents = k.includes('_cents');
    let val = values ? values[k] : 0;
    val = val ?? 0;
    result[k] = isCents ? currencyCents(val) : val;
  });
  return result;
};
