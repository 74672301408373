
































































































import password from '@/store/types/profile';
import rootType from '@/store/types/root';
import { VuexFunction } from '@/ts/axios';
import { TeipNotifications } from '@/util/teipNotifications';
import { AxiosError } from 'axios';
import { ValidationProvider } from 'vee-validate';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

type dataType = {
  icon: string;
  name: string;
  value: string;
  type: string;
};

@Component({
  name: 'ConfigurationCurrentUser',
})
class ConfigurationCurrentUser extends Vue {
  $refs!: {
    formUpdatePassword: InstanceType<typeof ValidationProvider>;
  };

  data: dataType[] = [
    {
      icon: 'fas fa-eye',
      name: 'current_password',
      value: '',
      type: 'password',
    },
    { icon: 'fas fa-eye', name: 'password', value: '', type: 'password', },
    {
      icon: 'fas fa-eye',
      name: 'password_confirmation',
      value: '',
      type: 'password',
    }
  ];

  updatePassword(): void {
    const data = {
      current_password: this.$data.data[0].value,
      password: this.$data.data[1].value,
      password_confirmation: this.$data.data[2].value,
    };
    const notification = new TeipNotifications();
    this.resetPasswordUser(data)
      .then(() => {
        notification.success('¡Éxito!', 'Contraseña actualizada');

        this.$data.data[0].value = '';
        this.$data.data[1].value = '';
        this.$data.data[2].value = '';
        this.$refs.formUpdatePassword.reset();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 422) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const typeError: any = error.response.data.error;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let errors: any = [];
          if (typeof typeError === 'string') {
            errors.current_password = 'Contraseña actual incorrecta';
          } else {
            errors = typeError;
          }
          this.$refs.formUpdatePassword.setErrors(errors);
        } else {
          notification.warning('¡Error!', 'No hemos podido actualizar');
        }
      });
  }

  showPassword(input: string): void {
    const position = this.$data.data.find(
      (inp: dataType) => inp.name === input
    );
    if (position.type === 'password') {
      position.type = 'text';
      position.icon = 'fas fa-eye-slash';
    } else {
      position.type = 'password';
      position.icon = 'fas fa-eye';
    }
  }

  cancel(): void {
    this.$router.push({
      name: 'MyProfile',
    });
  }

  @Action(`profileModule/${password.actions.RESET_PASSWORD_CURRENT_USER}`)
  resetPasswordUser!: VuexFunction;

  @Getter(rootType.getters.GET_START_PROCESS) getProcess!: boolean;
}

export default ConfigurationCurrentUser;
