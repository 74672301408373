





































































































import DatesFormat from '@/util/classes/datesFormat';
import { money } from '@/util/filters';
import Vue, { PropType } from 'vue';
import { tDinamicPayment } from '@/store/interfaces/dinamicPayments';
import SidebarModal from '@/components/Reusable/SidebarModal.vue';
import { TeipNotifications } from '@/util/teipNotifications';

const formatDate = new DatesFormat();

export default Vue.extend({
  name: 'DetailSidebarModal',
  components: {
    SidebarModal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    dinamicPayment: {
      type: Object as PropType<tDinamicPayment>,
      required: true,
    },
  },
  data() {
    return {
      money,
    };
  },
  methods: {
    formatDate(date: string): string {
      return formatDate.formatDateTime(date);
    },
    copyLink(): void {
      const notification = new TeipNotifications();
      const url = this.dinamicPayment.payment_link;

      navigator.clipboard.writeText(url);

      notification.success('Copiado!!', url);
    },
    editDinamicPayment(): void {
      this.$emit('edit', true);
      this.showSidebarBar = false;
    },
  },
  computed: {
    showSidebarBar: {
      get(): boolean {
        return this.show;
      },
      set(v: boolean): void {
        this.$emit('update:show', v);
      },
    },
    total: {
      get(): number {
        return (
          (this.dinamicPayment.subtotal_cents + this.dinamicPayment.tip_cents) /
          100
        );
      },
    },
  },
});
