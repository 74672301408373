

















































import auth from '@/store/types/auth';
import root from '@/store/types/root';
import { VuexFunction } from '@/ts/axios';
import { TeipNotifications } from '@/util/teipNotifications';
import { AxiosError, AxiosResponse } from 'axios';
import { ValidationProvider } from 'vee-validate';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  name: 'SetupPassword',
})
export default class extends Vue {
  @Ref('setupPasswordForm') readonly setupPasswordForm!: InstanceType<
    typeof ValidationProvider
  >;

  model = {
    password: '',
    password_confirmation: '',
  };

  showPassword = false;
  cn = 'Empresa desconocida';
  showPasswordValidationRequired = false;

  async setPassword(): Promise<void> {
    const data = {
      ...this.$data.model,
      code: this.$route.params.code,
    };
    await this.firstPassword(data)
      .then((res: AxiosResponse) => {
        this.authorize({
          password: data.password,
          email: res.data.data.email,
        }).then(() => {
          this.$router.push({
            name: 'Landing',
            query: {
              cn: this.$data.cn,
            },
          });
        });
      })
      .catch((err: AxiosError) => {
        const notification = new TeipNotifications();
        if (err.response?.status === 422) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let error: any = [];
          if (Object.keys(err.response.data.error).includes('password')) {
            error['contraseña'] = err.response.data.error.password;
          } else {
            error = err.response.data.error;
          }
          this.setupPasswordForm.setErrors(error);
          this.$data.showPasswordValidationRequired = true;
        } else if (err.response?.status === 404) {
          notification.warning(
            'Código de autorización ha expirado',
            'Favor solicitar la invitación nuevamente'
          );
        } else {
          notification.warning('Algo ha salido mal :(');
        }
      });
  }

  mounted(): void {
    if (this.$route.query.cn) {
      this.$data.cn = this.$route.query.cn;
    }
  }

  iconClick(): void {
    this.$data.showPassword = !this.$data.showPassword;
  }

  @Getter(root.getters.GET_START_PROCESS) getProcess!: boolean;

  @Action(`authModule/${auth.actions.SETUP_PASSWORD}`)
  firstPassword!: VuexFunction;

  @Action(`authModule/${auth.actions.AUTHORIZE}`) authorize!: VuexFunction;
}
