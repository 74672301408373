
import { Moment } from 'moment-timezone';

export const dateParser = (rangeString: string, today: Moment): Array<string> => {
  let dates: Array<string> = [''];
  let fromDate = '';
  let toDate = '';
  switch (rangeString) {
  case 'today':
    dates = [parseToFormat(today), parseToFormat(today)];
    break;
  case 'current_week':
    toDate = parseToFormat(today);
    fromDate = parseToFormat(today.startOf('week'));
    dates = [fromDate, toDate];
    break;
  case 'current_month':
    toDate = parseToFormat(today);
    fromDate = parseToFormat(today.startOf('month'));
    dates = [fromDate, toDate];
    break;
  case 'yesterday':
    dates = [parseToFormat(today.subtract(1, 'day')), parseToFormat(today)];
    break;
  case 'last_week':
    today = today.subtract(1, 'week');
    fromDate = parseToFormat(today.startOf('week'));
    toDate = parseToFormat(today.endOf('week'));
    dates = [fromDate, toDate];
    break;
  case 'last_month':
    today = today.subtract(1, 'month');
    fromDate = parseToFormat(today.startOf('month'));
    toDate = parseToFormat(today.endOf('month'));
    dates = [fromDate, toDate];
    break;
  case 'l7':
    toDate = parseToFormat(today);
    fromDate = parseToFormat(today.subtract(7, 'day'));
    dates = [fromDate, toDate];
    break;
  case 'l14':
    toDate = parseToFormat(today);
    fromDate = parseToFormat(today.subtract(14, 'days'));
    dates = [fromDate, toDate];
    break;
  case 'l28':
    toDate = parseToFormat(today);
    fromDate = parseToFormat(today.subtract(28, 'days'));
    dates = [fromDate, toDate];
    break;
  case 'l30':
    toDate = parseToFormat(today);
    fromDate = parseToFormat(today.subtract(30, 'days'));
    dates = [fromDate, toDate];
    break;
  case 'l60':
    toDate = parseToFormat(today);
    fromDate = parseToFormat(today.subtract(60, 'days'));
    dates = [fromDate, toDate];
    break;
  case 'l90':
    toDate = parseToFormat(today);
    fromDate = parseToFormat(today.subtract(90, 'days'));
    dates = [fromDate, toDate];
    break;
  }
  return dates;
};

const parseToFormat = (date: Moment) => {
  return date.format('Y-MM-DD');
};
