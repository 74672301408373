import { GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';
import globalConfig from '@/store/types/globalConfig';
import { GlobalState } from '@/store/interfaces/gloablConfig';

const namespaced = true;
const initialState = (): GlobalState => {
  return {
    navbarState: true,
  };
};

const state: GlobalState = initialState();
const getters: GetterTree<GlobalState, RootState> = {
  [globalConfig.getters.GET_NAVBAR_STATE]: state => {
    return state.navbarState;
  },
};

const mutations: MutationTree<GlobalState> = {
  reset: state => {
    const initialStateFields = initialState();
    Object.entries(initialStateFields).forEach(([key, value]) => {
      state[key] = value;
    });
  },
  [globalConfig.mutations.SET_NAVBAR_STATE]: (state, show: boolean) => {
    state.navbarState = show;
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
};
