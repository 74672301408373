export default {
  actions: {
    SEARCH_HANDLE: 'SEARCH_HANDLE',
    SUBMIT_PAYMENT: 'SUBMIT_PAYMENT',
    REMOTE_PAYMENT_LIST: 'REMOTE_PAYMENT_LIST',
    REMOTE_PAYMENT_SINGLE: 'REMOTE_PAYMENT_SINGLE',
    SET_ORDER_PAYMENT: 'SET_ORDER_PAYMENT',
    CANCEL_PAYMENT: 'CANCEL_PAYMENT',
    RETRY_PAYMENT: 'RETRY_PAYMENT',
  },
  getters: {
    GET_PAYMENTS: 'GET_PAYMENTS',
    GET_PAYMENT: 'GET_PAYMENT',
  },
  mutations: {
    SET_PAYMENTS: 'SET_PAYMENTS',
    SET_PAYMENT: 'SET_PAYMENT',
  },
};
