


















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  name: 'TeipSidebar',
  components: {
  },
})
export default class TeipSidebar extends Vue {
  @PropSync('openModal', {
    type: Boolean,
    required: true,
  }) syncedOpenModal!: boolean

  @Prop({
    required: true,
    type: String,
  }) title!: string

  triggerDetail(val: boolean): void {
    this.syncedOpenModal = val;
  }
}
