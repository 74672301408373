<template>
  <div>
    <!-- Header -->
    <div class="header">
      <b-container>
        <div class="header-body text-center">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="6">
              <h1 class="not-found text-muted">404!</h1>
              <p class="display-2 text-light">
                Página no encontrada :(
              </p>

              <router-link to="/" class="font-weight-bold text-warning mt-5">Volver a inicio</router-link>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
<style lang="scss" scoped>
.not-found {
  font-size: 10rem;
}
</style>
