
































import { Component, Vue } from 'vue-property-decorator';
import newPayment from '@/components/RemotePayment/NewPayment.vue';
import paymentDetail from '@/components/RemotePayment/PaymentDetail.vue';
import paymentList from '@/components/RemotePayment/PaymentList.vue';
import { Action, Getter } from 'vuex-class';
import remotePaymentType from '@/store/types/remotePayment';
import { companyOrderParams } from '@/store/interfaces/remotePayment';
import authType from '@/store/types/auth';
import { AuthUserParams } from '@/store/interfaces/auth';
import { VuexFunction } from '@/ts/axios';
import SidebarModal from '@/components/Reusable/SidebarModal.vue';
import PaymentLinkModal from '@/components/RemotePayment/modals/PaymentLink.vue';

@Component({
  name: 'RemotePaymentHome',
  components: {
    newPayment,
    paymentList,
    SidebarModal,
    paymentDetail,
    PaymentLinkModal,
  },
})
export default class RemotePaymentHome extends Vue {
  openNewPaymentModal = false;
  openPaymentDetail = false;
  openPaymentLinkModal = false;
  reloadTable = false;

  payment: null | companyOrderParams = null;

  reFetch(): void {
    this.openPaymentDetail = false;
    this.openNewPaymentModal = false;
    this.reloadTable = !this.reloadTable;
  }

  setValue(val: companyOrderParams): void {
    this.setOrderPayment(val);
  }

  triggerDetail(val: companyOrderParams): void {
    this.openNewPaymentModal = false;
    this.payment = val;
    this.openPaymentDetail = !!val;
  }

  triggerNew(val: boolean): void {
    this.openPaymentDetail = false;
    this.openNewPaymentModal = val;
  }

  @Getter(`authModule/${authType.getters.GET_AUTH_USER}`)
  getUser!: AuthUserParams;

  @Action(`remotePaymentModule/${remotePaymentType.actions.SET_ORDER_PAYMENT}`)
  setOrderPayment!: VuexFunction;
}
