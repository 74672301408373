























































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { VuexFunction } from '@/ts/axios';
import { propsToSearch, tableColumns } from '@/ts/tables';
import transactions from '@/store/types/transactions';
import { tDinamicPayment, tNewDinamicPayment } from '@/store/interfaces/dinamicPayments';
import {
  allowAny,
  responseDataPagination
} from '@/store/interfaces/generic';
import SidebarModal from '@/components/Reusable/SidebarModal.vue';
import DatesFormat from '@/util/classes/datesFormat';
import auth from '@/store/types/auth';
import CreditCard from '@/components/Reusable/CreditCard.vue';
import { money } from '@/util/filters';
import Status from '@/components/Reusable/Status.vue';
import type { tTeipTableRef } from 'teip-components';
import DetailSidebarModal from '@/components/DinamicPayments/DetailSidebarModal.vue';
const formatDate = new DatesFormat();

@Component({
  components: {
    DetailSidebarModal,
    SidebarModal,
    CreditCard,
    Status,
    NewPaymentSidebarModal: () => import('@/components/DinamicPayments/NewPaymentSidebarModal.vue'),
  },
})
export default class DinamicsPayments extends Vue {
  @Ref('refDinamicPayments')
  readonly refDinamicPayments!: tTeipTableRef;

  query = '';
  showSidebar = false;
  renderNewPaymentModalForFirstTime = false;
  updateDinamicPayment = false;
  showTransaction = false;
  updateIsInProcess = true;
  interval: allowAny = null;
  propsToSearch: propsToSearch = [];
  tableColumns: tableColumns = [
    {
      prop: 'branch_name',
      label: 'Sucursal',
      minWidth: 250,
      sortable: true,
    },
    {
      prop: 'user_name',
      label: 'Nombre usuario',
      minWidth: 250,
      sortable: true,
    },
    {
      prop: 'start_at',
      label: 'Fecha de inicio',
      minWidth: 220,
      formatter: (row: tDinamicPayment): string => {
        return `${formatDate.formatDateTime(row.start_at)}`;
      },
    },
    {
      prop: 'start_end',
      label: 'Fecha fin',
      minWidth: 220,
      formatter: (row: tDinamicPayment): string => {
        return `${formatDate.formatDateTime(row.end_at)}`;
      },
    },
    {
      prop: 'occurrences_count',
      label: 'Número de ocurrencia',
      minWidth: 180,
    },
    {
      prop: 'total_formatted',
      label: 'Monto Total',
      minWidth: 220,
      sortable: true,
      formatter: (row: tDinamicPayment): string => {
        return money(row.subtotal_cents / 100);
      },
    }
  ];

  money = money

  row: tDinamicPayment = {
    id: '',
    branch_id: '',
    user_id: '',
    subtotal_cents: 0,
    subtotal_currency: 'USD',
    fee_cents: 0,
    fee_currency: 'USD',
    delivery_fee_cents: 0,
    delivery_fee_currency: 'USD',
    tip_cents: 0,
    tip_currency: 'USD',
    code: '',
    description: '',
    created_at: '',
    updated_at: '',
    start_at: '',
    end_at: '',
    occurrences_count: 0,
    payment_link: '',
    branch_name: '',
    user_name: '',
  };

  get getNewDinamicPayment(): tNewDinamicPayment {
    return {
      id: this.row.id,
      code: this.row.code,
      branch_id: this.row.branch_id,
      subtotal_cents: this.row.subtotal_cents / 100,
      description: this.row.description,
      start_at: this.row.start_at,
      end_at: this.row.end_at,
      occurrences_count: this.row.occurrences_count,
    };
  }

  formatDate(date: string):string {
    return formatDate.formatDateTime(date);
  }

  async mounted(): Promise<void> {
    this.updateDinamicPayments();
    this.updateIsInProcess = false;
  }

  rowSelected(transaction: tDinamicPayment): void {
    this.row = JSON.parse(JSON.stringify(transaction));
    this.showTransaction = true;
  }

  async updateDinamicPayments(): Promise<void> {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.updateIsInProcess = true;

      await this.refDinamicPayments.fetchData();
      this.updateIsInProcess = false;

      this.refDinamicPayments.withSkeleton = true;
    }
    this.interval = setInterval(async () => {
      this.refDinamicPayments.withSkeleton = false;
      if (!this.showSidebar) {
        await this.refDinamicPayments.fetchData();
      }
      this.refDinamicPayments.withSkeleton = true;
    }, 30000);
  }

  destroyed(): void {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  editDinamicPayment(): void {
    this.renderNewPaymentModalForFirstTime = true;
    this.showSidebar = true;
    this.updateDinamicPayment = true;
  }

  newDinamicPayment(): void {
    this.showSidebar = true;
    if (!this.renderNewPaymentModalForFirstTime) {
      this.renderNewPaymentModalForFirstTime = true;
    }

    this.updateDinamicPayment = false;
    this.row.id = '';
    this.row.code = '';
    this.row.branch_id = '';
    this.row.subtotal_cents = 0;
    this.row.description = '';
    this.row.start_at = '';
    this.row.end_at = '';
    this.row.occurrences_count = 0;
  }

  @Watch('companySelected')
  onCompanySelectedChanged(val: string, oldVal: string): void {
    if (val !== oldVal) {
      this.refDinamicPayments.fetchData();
    }
  }

  @Getter(`transactionsModule/${transactions.getters.GET_DINAMIC_PAYMENTS}`)
  getDinamicPayments!: responseDataPagination<tDinamicPayment>;

  @Action(`transactionsModule/${transactions.actions.DINAMIC_PAYMENTS}`)
  dinamicPayments!: VuexFunction;

  @Getter(`authModule/${auth.getters.GET_COMPANY}`)
  companySelected?: string;
}
