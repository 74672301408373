



















































import globalConfig from '@/store/types/globalConfig';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
@Component({
  name: 'Sidebar'
})
export default class Sidebar extends Vue {
  @Prop({
    type: String,
    default: 'teip'
  })
  title!: string;
  @Prop({
    type: String,
    default: 'te'
  })
  shortTitle!: string;
  @Prop({
    type: String
  })
  logo!: string;
  @Prop({
    type: String,
    default: 'vue',
    validator: (value: string) => {
      const acceptedValues = [
        '',
        'vue',
        'blue',
        'green',
        'orange',
        'red',
        'primary'
      ];
      return acceptedValues.indexOf(value) !== -1;
    }
  })
  backgroundColor!: string;

  @Prop({
    type: Array,
    default: () => []
  })
  sidebarLinks!: string[];

  @Prop({
    type: Boolean,
    default: true
  })
  autoClose!: string;

  isDesktop = false;
  menuClicked = false;

  provide() {
    return {
      autoClose: this.autoClose
    };
  }
  minimizeSidebar() {
    if (this.isDesktop) {
      this.menuClicked = this.$sidebar.isMinimized;
      this.$emit('isOpenSidebar', this.$sidebar.isMinimized);
    } else {
      this.menuClicked = true;
      this.$emit('isOpenSidebar', true);
    }

    if (this.$sidebar) {
      this.$sidebar.toggleMinimize();
      if (this.isDesktop) {
        this.$store.commit(
          `globalConfig/${globalConfig.mutations.SET_NAVBAR_STATE}`,
          !this.$sidebar.isMinimized
        );
      } else {
        this.$store.commit(
          `globalConfig/${globalConfig.mutations.SET_NAVBAR_STATE}`,
          false
        );
      }
    }
  }

  mauseEnter() {
    this.$emit('isOpenSidebar', true);
    this.$sidebar.onMouseEnter();
  }

  mauseLeave() {
    this.$emit('isOpenSidebar', this.menuClicked);
    this.$sidebar.onMouseLeave();
  }

  mounted() {
    this.isDesktop = this.$sidebar.breakpoint < window.innerWidth;
    if (this.isDesktop) {
      this.$sidebar.isMinimized = this.getNavbarState;
    } else {
      this.$sidebar.isMinimized = false;
    }
    this.minimizeSidebar();
  }
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }

  @Getter(`globalConfig/${globalConfig.getters.GET_NAVBAR_STATE}`)
  getNavbarState!: boolean;
}
