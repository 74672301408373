










import masterCard from '@/assets/images/mastercard.svg';
import visa from '@/assets/images/visa.svg';
import { VueConstructor } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  name: 'CreditCard',
})
export default class creditCard extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  card_network!: string;

  @Prop({
    required: true,
    type: String,
  })
  card_last_digits!: string;

  data(): {visa: VueConstructor<Vue>, masterCard: VueConstructor<Vue>} {
    return {
      visa,
      masterCard,
    };
  }
}
