


















































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  name: 'CustomStatCard',
})

export default class StatCard extends Vue {
  @Prop({ required: true, type: String, })
  title!: string

  @Prop({ required: false, type: [String, Number], default: '', })
  subTitle!: string | number

  @Prop({ required: false, type: String, default: '', })
  icon!: string

  @Prop({ required: false, type: String, default: '', })
  subTitleClasses!: string

  @Prop({ required: false, type: String, default: '', })
  tooltipLabel!: string

  @Prop({ required: false, type: Boolean, default: false, })
  hasTooltip!: boolean
}

