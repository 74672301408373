






























import { Component, Vue, Watch, Prop, PropSync } from 'vue-property-decorator';
import { CommerceDataPagination, IdName } from '@/store/interfaces/commerce';
import branches from '@/store/types/branches';
import commerces from '@/store/types/commerces';
import authType from '@/store/types/auth';
import { Action, Getter } from 'vuex-class';
import { VuexFunction } from '@/ts/axios';
import { responseDataPagination } from '@/store/interfaces/generic';

@Component
export default class CommerceBranchesSelect extends Vue {
  @Prop({ type: Boolean, default: false, }) disableCommerce!: boolean;
  @Prop({ type: Boolean, default: false, }) disableBranch!: boolean;

  cleanInputs = true;

  @PropSync('commerceId', {
    required: true,
    type: String,
  })
  commerceIdSync!: string;

  @PropSync('branchId', {
    required: true,
    type: String,
  })
  branchIdSync!: string;

  @Watch('companySelected')
  async onCompanySelectedChanged(val: string): Promise<void> {
    if (val) {
      await this.commercesList();
    }
  }

  @Watch('commerceId')
  onCommerceIdChange(val: string): void {
    if (val) {
      this.branchesList({ commerce_id: val, params: 'per_page=100', });
    }
  }

  @Action(`commercesModule/${commerces.actions.COMMERCE_LIST}`)
  commercesList!: VuexFunction;

  @Action(`branchesModule/${branches.actions.BRANCHES_LIST}`)
  branchesList!: VuexFunction;

  @Getter(`branchesModule/${branches.getters.GET_BRANCHES_LIST}`)
  branches!: responseDataPagination<IdName>;

  @Getter(`commercesModule/${commerces.getters.GET_COMMERCE_LIST}`)
  commerces!: CommerceDataPagination;

  @Getter(`authModule/${authType.getters.GET_COMPANY}`)
  companySelected?: string | undefined;
}
