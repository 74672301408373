






























































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ScanFilter from '@/components/Sales/scanToPayFilter.vue';
import commerces from '@/store/types/commerces';
import { VuexFunction } from '@/ts/axios';
import { CommerceDataPagination, IdName } from '@/store/interfaces/commerce';
import branches from '@/store/types/branches';
import { propsToSearch, tableColumns } from '@/ts/tables';
import transactions from '@/store/types/transactions';
import { TransactionParams } from '@/store/interfaces/transactions';
import {
  allowAny,
  responseDataPagination,
  TableRef
} from '@/store/interfaces/generic';
import SidebarModal from '@/components/Reusable/SidebarModal.vue';
import DatesFormat from '@/util/classes/datesFormat';
import auth from '@/store/types/auth';
import scanToPayForm from '@/components/Sales/scanToPayForm.vue';
import { BrancheSignature } from '@/store/interfaces/branches';
import transactionInfo from '@/components/RemotePayment/modals/transactionInfo.vue';
import { TeipNotifications } from '@/util/teipNotifications';
import CreditCard from '@/components/Reusable/CreditCard.vue';
import { money } from '@/util/filters';
import Status from '@/components/Reusable/Status.vue';
const formatDate = new DatesFormat();

@Component({
  name: 'ScanToPay',
  components: {
    ScanFilter,
    SidebarModal,
    scanToPayForm,
    transactionInfo,
    CreditCard,
    Status,
  },
})
export default class ScanToPay extends Vue {
  @Ref('refScanToPayTransactionsList')
  readonly refScanToPayTransactionsList!: TableRef;

  query = '';
  signature = '';
  branchName = '';
  showSidebar = false;
  showTransaction = false;
  updateIsInProcess = true;
  errorSignature = false;
  interval: allowAny = null;
  propsToSearch: propsToSearch = [];
  tableColumns: tableColumns = [
    {
      prop: 'commerce_name',
      label: 'Comercio',
      minWidth: 250,
      sortable: true,
    },
    {
      prop: 'branch_name',
      label: 'Sucursal',
      minWidth: 250,
      sortable: true,
    },
    {
      prop: 'user_name',
      label: 'Nombre usuario',
      minWidth: 220,
      sortable: true,
    },
    {
      prop: 'total_formatted',
      label: 'Monto Total',
      minWidth: 220,
      sortable: true,
      formatter: (row: TransactionParams): string => {
        return money(
          (row.subtotal_cents + row.discount_cents + row.tip_cents) / 100
        );
      },
    },
    {
      prop: 'card_network',
      label: 'Métodos de pago',
      minWidth: 220,
      slotName: 'card',
    },
    {
      prop: 'status',
      label: 'Estado',
      minWidth: 220,
      sortable: true,
      slotName: 'status',
    },
    {
      prop: 'created_at',
      label: 'Fecha y hora',
      minWidth: 220,
      formatter: (row: TransactionParams): string => {
        return `${formatDate.formatDateTime(row.created_at)}`;
      },
    }
  ];

  isDownloadVoucher = false;
  isSendingEmail = false;
  row: TransactionParams | null = null;

  async mounted(): Promise<void> {
    await this.commercesList();
    await this.branchesSignatureList();
    this.updateTransactionList();
    this.updateIsInProcess = false;
  }

  searchingData(query: string): void {
    this.query = query;
  }

  resentEmail(id: string): void {
    this.isSendingEmail = true;
    const notification = new TeipNotifications();
    this.resentE(id)
      .then(() => {
        notification.success('Correo reenviado con éxito');
      })
      .catch(() => {
        notification.warning('Algo salió mal :(');
      })
      .finally(() => {
        this.isSendingEmail = false;
      });
  }

  async downloadVoucher(id: string): Promise<void> {
    this.isDownloadVoucher = true;
    await this.downloadV({
      id,
      label: `Voucher ${this.$data.row.user_name}`,
    });
    this.isDownloadVoucher = false;
  }

  rowSelected(transaction: TransactionParams): void {
    this.row = transaction;
    this.showTransaction = true;
  }

  async updateTransactionList(): Promise<void> {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.updateIsInProcess = true;

      await this.refScanToPayTransactionsList.fetchData();
      this.updateIsInProcess = false;

      this.refScanToPayTransactionsList.withSkeleton = true;
    }
    this.interval = setInterval(async () => {
      this.refScanToPayTransactionsList.withSkeleton = false;
      if (!this.showSidebar) {
        await this.refScanToPayTransactionsList.fetchData();
      }
      this.refScanToPayTransactionsList.withSkeleton = true;
    }, 30000);
  }

  destroyed(): void {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  async commerceId(commerce_id: string): Promise<void> {
    if (commerce_id) {
      await this.branchesList({ commerce_id, params: 'per_page=100', });
      this.signature = '';
    }
  }

  async branchId(branch_id: string): Promise<void> {
    if (branch_id) this.signatureByBranch(branch_id);
  }

  signatureByBranch(branchId: string): void {
    const signature = this.getBranchesSignatures.data.find(sign => {
      return sign.branch.id === branchId;
    });
    this.errorSignature = false;

    if (signature) {
      this.branchName = String(signature?.branch.name);
      this.signature = `${process.env.VUE_APP_URL_QR}/?c=${signature?.branch.id}.${signature?.signature}`;
    } else {
      this.errorSignature = true;
      this.signature = '';
    }
  }

  newQrcode(): void {
    this.showSidebar = true;
  }

  @Watch('companySelected')
  onCompanySelectedChanged(val: string, oldVal: string): void {
    if (val !== oldVal) {
      this.refScanToPayTransactionsList.fetchData();
    }
  }

  @Getter(`commercesModule/${commerces.getters.GET_COMMERCE_LIST}`)
  getAllCommerces!: CommerceDataPagination;

  @Getter(`branchesModule/${branches.getters.GET_BRANCHES_LIST}`)
  getAllBranches!: responseDataPagination<IdName>;

  @Getter(`branchesModule/${branches.getters.GET_BRANCHES_SIGNATURE}`)
  getBranchesSignatures!: responseDataPagination<BrancheSignature>;

  @Getter(`transactionsModule/${transactions.getters.GET_SCAN_TO_PAY}`)
  getScanToPayTransactions!: responseDataPagination<TransactionParams>;

  @Action(`branchesModule/${branches.actions.BRANCHES_LIST}`)
  branchesList!: VuexFunction;

  @Action(`branchesModule/${branches.actions.BRANCHES_SIGNATURE}`)
  branchesSignatureList!: VuexFunction;

  @Action(`commercesModule/${commerces.actions.COMMERCE_LIST}`)
  commercesList!: VuexFunction;

  @Action(`transactionsModule/${transactions.actions.SCAN_TO_PAY}`)
  scanToPayTransactions!: VuexFunction;

  @Getter(`authModule/${auth.getters.GET_COMPANY}`)
  companySelected?: string | undefined;

  @Action(`transactionsModule/${transactions.actions.DOWNLOAD_VOUCHER}`)
  downloadV!: VuexFunction;

  @Action(`transactionsModule/${transactions.actions.RESENT_EMAIL}`)
  resentE!: VuexFunction;
}
