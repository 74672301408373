/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AuthPermissions, AuthState, AuthTokens } from '@/store/interfaces/auth';
import { RootState } from '@/store/interfaces/rootState';
import authType from '@/store/types/auth';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { base64URLEncode, sha256 } from '@/util/auth';
import crypto from 'crypto';
import RefreshToken from '@/util/classes/refreshTokenTime';
const namespaced = true;

const initialState = (): AuthState => {
  return {
    userSession: {
      access_token: '',
      created_at: 0,
      expires_in: 0,
      refresh_token: '',
      scope: '',
      token_type: '',
    },
    token: '',
    status: 'not_logged',
    canSeeCommerces: true,
    company: undefined,
    currentUser: {
      id: '',
      email: '',
      companies: [],
      company_users: [],
      profile: {
        id: '',
        first_name: '',
        last_name: '',
        gender: '',
        phone_number: '',
        birthdate: '',
        avatar_url: '',
      },
      permission_groups: [],
    },
    permission_groups: [],
    company_users: [],
  };
};

const state: AuthState = initialState();
const getters: GetterTree<AuthState, RootState> = {
  [authType.getters.AUTH_STATUS]: (state): string => {
    return state.status;
  },
  [authType.getters.IS_AUTHENTICATED]: state => {
    return !!state.token;
  },
  [authType.getters.AUTH_PERMISSIONS]: state => {
    return state.canSeeCommerces;
  },
  [authType.getters.PERMISSIONS]: state => {
    // let permissions: Array<string> | undefined = [];
    // permissions = state.permission_groups?.map((p: AuthPermissions) => p.slug);
    return state.permission_groups;
  },
  [authType.getters.GET_AUTH_USER]: state => {
    return state.currentUser;
  },
  [authType.getters.GET_COMPANY]: state => {
    return state.company;
  },
  [authType.getters.GET_USER_SESSION]: state => {
    return state.userSession;
  },
};
const mutations: MutationTree<AuthState> = {
  reset: state => {
    const initialStateFields = initialState();
    Object.keys(initialStateFields).forEach(key => {
      state[key] = initialStateFields[key];
    });
  },
  [authType.mutations.AUTH_REQUEST]: state => {
    state.status = 'loading';
  },
  [authType.mutations.AUTH_PERMISSIONS]: (state, resp) => {
    state.canSeeCommerces = resp;
  },
  [authType.mutations.AUTH_SUCCESS]: (state, resp: AuthTokens) => {
    state.status = 'success';
    state.userSession = resp;
    state.token = resp.access_token;
    localStorage.setItem('_token', resp.access_token);
    http.defaults.headers.common.Authorization = `Bearer ${resp.access_token}`;
  },
  [authType.mutations.AUTH_ERROR]: state => {
    state.status = 'error';
  },
  [authType.mutations.AUTH_LOGOUT]: state => {
    state.token = '';
  },
  [authType.mutations.SET_AUTH_USER]: (state, data) => {
    state.currentUser = data;
    if (data.companies.length > 0) {
      state.company = data.companies[0].id;
      state.permission_groups = data.company_users[0].permission_group.permissions
        .map((permission: AuthPermissions) => permission.slug);
      http.defaults.headers.common['X-COMPANY-ID'] = state.company;
    }
  },
  [authType.mutations.SET_COMPANY]: (state, company) => {
    state.company = company;

    const findCompany = state.currentUser.company_users
      .find(c => c.company_id === company);
    if (findCompany) {
      state.permission_groups = findCompany.permission_group.permissions.map((p) => p.slug);
    }
    // state.permission_groups =
    //   state.currentUser.company_users
    //     .find(c => c.company_id === company)?.permission_group.permissions;

    http.defaults.headers.common['X-COMPANY-ID'] = state.company;
  },
};
const actions: ActionTree<AuthState, RootState> = {
  [authType.actions.AUTHORIZE]: async ({ commit, dispatch, }, user) => {
    try {
      const token: AxiosResponse = await getToken();

      const getUserToken = {
        grant_type: 'password',
        email: user.email,
        password: user.password,
        scope: 'company',
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };
      const userToken: AxiosResponse = await http.post(
        'oauth/token',
        getUserToken,
        config
      );
      commit(authType.mutations.AUTH_SUCCESS, userToken.data);
      const currentUser: AxiosResponse = await http.get('companies/user');

      commit(authType.mutations.SET_AUTH_USER, currentUser.data.data);
      dispatch(authType.actions.EXPIRES_IN);
    } catch (err) {
      throw err;
    }
  },
  [authType.actions.RE_FETCH_USER_INFO]: async({ commit, }) => {
    const currentUser: AxiosResponse = await http.get('companies/user');
    commit(authType.mutations.SET_AUTH_USER, currentUser.data.data);
  },
  [authType.actions.SEND_PASSWORD_INSTRUCTIONS]: async (_, user) => {
    try {
      const token: AxiosResponse = await getToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };

      await http.post(
        'accounts/passwords',
        { data: { email: user.email, }, },
        config
      );
    } catch (err) {
      throw err;
    }
  },
  [authType.actions.RESET_PASSWORD]: async (_, data) => {
    try {
      const token: AxiosResponse = await getToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };
      await http.put('accounts/passwords', { data: data, }, config);
    } catch (err) {
      throw err;
    }
  },
  [authType.actions.SEND_UNLOCK_INSTRUCTIONS]: async (_, user) => {
    try {
      const token: AxiosResponse = await getToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };

      await http.post(
        'accounts/unlocks',
        { data: { email: user.email, }, },
        config
      );
    } catch (err) {
      throw err;
    }
  },
  [authType.actions.UNLOCK_ACCOUNT]: async (_, code: string) => {
    try {
      const token: AxiosResponse = await getToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };
      await http.post(`accounts/unlocks/${code}`, {}, config);
    } catch (err) {
      throw err;
    }
  },

  [authType.actions.REFRESH_TOKEN]: async ({ state, commit, dispatch, }) => {
    if (state.token) {
      const getUserToken = {
        refresh_token: state.userSession.refresh_token,
        grant_type: 'refresh_token',
      };

      const config = {
        headers: {
          Authorization: `Bearer ${state.userSession.access_token}`,
        },
      };
      http
        .post('oauth/token', getUserToken, config)
        .then((res: AxiosResponse) => {
          commit(authType.mutations.AUTH_SUCCESS, res.data);
          dispatch(authType.actions.EXPIRES_IN);
        })
        .catch(() => {
          dispatch(authType.actions.AUTH_LOGOUT);
        });
    }
  },
  [authType.actions.EXPIRES_IN]: ({ state, dispatch, }) => {
    const timeToExpiredToken = new RefreshToken(state.userSession);
    const time = timeToExpiredToken.timeToRefresh();
    // time must not be exceed 2147483647 milliseconds
    if (time > 0) {
      setTimeout(() => {
        dispatch(authType.actions.REFRESH_TOKEN);
      }, time);
    } else {
      dispatch(authType.actions.REFRESH_TOKEN);
    }
  },
  [authType.actions.SET_COMPANY]: async ({ commit, }, company) => {
    return new Promise(resolve => {
      commit(authType.mutations.SET_COMPANY, company);
      resolve(true);
    });
  },
  [authType.actions.AUTH_LOGOUT]: ({ commit, }) => {
    return new Promise(resolve => {
      commit(authType.mutations.AUTH_LOGOUT);
      localStorage.removeItem('_token');
      localStorage.removeItem('teip');
      commit('reset');
      commit('globalConfig/reset', null, { root: true, });
      resolve(true);
    });
  },
  [authType.actions.SETUP_PASSWORD]: async (_, data:
    { password: string, password_confirmation: string, code: string }
  ) => {
    const token: AxiosResponse = await getToken(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token.data.access_token}`,
      },
    };
    return new Promise((resolve, reject) => {
      http
        .post(
          'accounts/passwords/setup',
          { data, },
          config
        )
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

const getToken = async (account = false): Promise<AxiosResponse> => {
  const UID: string | undefined = account
    ? process.env.VUE_APP_ACCOUNT_UID
    : process.env.VUE_APP_UID;
  const REDIRECT_URI: string | undefined = process.env.VUE_APP_REDIRECT_URI;
  const verifier = base64URLEncode(crypto.randomBytes(32));
  const codeChallenge = base64URLEncode(sha256(verifier));
  const authorize = {
    client_id: UID,
    redirect_uri: REDIRECT_URI,
    response_type: 'code',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  };

  try {
    const code: AxiosResponse = await http.post(
      'oauth/application/authorize',
      authorize
    );
    const getToken = {
      client_id: UID,
      code: code.data.redirect_uri.code,
      code_verifier: verifier,
      redirect_uri: REDIRECT_URI,
      grant_type: 'authorization_code',
    };
    const token: AxiosResponse = await http.post(
      'oauth/application/token',
      getToken
    );
    return token;
  } catch (err) {
    throw err;
  }
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
