/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';

import { Getter } from 'vuex-class';
import authType from '@/store/types/auth';

// @ts-ignore
@Component({
  name: 'ReloadComponentMixin',
})
export default class ReloadComponentMixin extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data(): any {
    return {
      counter: 0,
    };
  }

  @Getter(`authModule/${authType.getters.GET_COMPANY}`)
  companySelected?: string | undefined;

  // @ts-ignore
  @Watch('companySelected')
  onCompanyChanged(val: string): void {
    if (val) {
      this.$data.counter++;
    }
  }
}
