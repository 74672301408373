











import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import bSidebar from '@/components/Reusable/bSidebar.vue';

@Component({
  name: 'SidebarModal',
  components: { bSidebar, },
})
export default class SidebarModal extends Vue {
  @PropSync('show', {
    required: true,
    type: Boolean,
  }) syncedShow!: boolean

  @Prop({
    required: true,
    type: String,
  }) title!: string
}
