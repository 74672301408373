


































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { FadeTransition } from 'vue2-transitions';
import {
  newCompanyOrder,
  RemotePayment,
  sessionPaymentRequest,
  tNewPayment
} from '@/store/interfaces/remotePayment';
import { v4 as uuidv4 } from 'uuid';
import {
  CommerceSettings
} from '@/store/interfaces/commerce';
import commerces from '@/store/types/commerces';
import { Action, Getter } from 'vuex-class';
import newPaymentFirstStep from './Steps/FirstStep.vue';
import newPaymentSecondStep from './Steps/SecondStep.vue';
import CommerceBranchesSelect from './CommerceBranchesSelect.vue';
import { TeipNotifications } from '@/util/teipNotifications';
import remotePayment from '@/store/types/remotePayment';
import { VuexFunction } from '@/ts/axios';
import { ValidationProvider } from 'vee-validate';

@Component({
  name: 'NewPayment',
  components: {
    newPaymentFirstStep,
    newPaymentSecondStep,
    CommerceBranchesSelect,
    FadeTransition,
  },
})
export default class NewPayment extends Vue {
  step = 1;
  commerceId = '';
  session: sessionPaymentRequest | null = null;
  paymentInfo: tNewPayment = {
    commerceId: '',
    branchId: '',
    amount: 0,
    teipTip: 0,
    deliveryFee: 0,
    totalCents: 0,
    user: '',
    description: '',
  };

  @Watch('commerceId')
  async onCommerceIdChange(val: string): Promise<void> {
    this.paymentInfo.branchId = '';
    this.paymentInfo.commerceId = val;
    if (val) {
      // this.branchesList({ commerce_id: val, params: 'per_page=100', });
      await this.getCommerceSettings(val);
      this.paymentInfo.teipTip =
        (this.paymentInfo.amount * this.commerceSettings.tip_percentage) / 100;
    }
  }

  setSession(ss: sessionPaymentRequest): void {
    this.session = ss;
  }

  goToStep(val: number): void {
    this.step = val;
  }

  clear(): void {
    this.paymentInfo.amount = 0;
    this.paymentInfo.teipTip = 0;
    this.paymentInfo.deliveryFee = 0;
    this.paymentInfo.user = '';
    this.paymentInfo.description = '';
    this.paymentInfo.totalCents = 0;
    this.step = 1;
    this.formNewPayment.reset();
  }

  async submitNewPayment(): Promise<void> {
    const isValid = await this.formNewPayment.validate();
    if (!isValid) {
      return;
    }
    if (this.step === 1) {
      this.step = 2;
    } else if (this.step === 2) {
      const form: RemotePayment = this.paymentInfo;
      const referenceId = uuidv4();
      const currency = 'USD';
      const companyOrderParams: newCompanyOrder = {
        branch_id: form.branchId,
        tip_cents: form.teipTip * 100,
        tip_currency: currency,
        fee_cents: form.deliveryFee * 100,
        fee_currency: currency,
        subtotal_cents: form.amount * 100,
        subtotal_currency: currency,
        reference_id: referenceId,
        latitude: '',
        longitude: '',
        kind: 'handle',
        description: form.description,
        payment_requests: [
          {
            user_id: String(this.session?.user.id),
            tip_cents: form.teipTip * 100,
            tip_currency: currency,
            fee_cents: form.deliveryFee * 100,
            fee_currency: currency,
            subtotal_cents: form.amount * 100,
            subtotal_currency: currency,
            reference_id: referenceId,
            session_id: String(this.session?.id),
          }
        ],
      };
      const notification = new TeipNotifications();
      try {
        await this.submitPayment(companyOrderParams);
        notification.success(
          'El cobro se ha enviado con éxito, visita el detalle para ver el status',
          '',
          'ni ni-bell-55'
        );
        this.$emit('reFetch');
        this.clear();
      } catch (_) {
        notification.warning('Algo ha salido mal :(');
      }
    }
  }

  @Ref('formNewPayment') readonly formNewPayment!: InstanceType<
    typeof ValidationProvider
  >;

  @Action(`commercesModule/${commerces.actions.COMMERCE_SETTINGS}`)
  getCommerceSettings!: VuexFunction;

  @Getter(`commercesModule/${commerces.getters.GET_COMMERCE_SETTINGS}`)
  commerceSettings!: CommerceSettings;

  @Action(`remotePaymentModule/${remotePayment.actions.SUBMIT_PAYMENT}`)
  submitPayment!: VuexFunction;
}
