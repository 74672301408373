export type selectOptionsValue = {
  label: string;
  options: {name: string, id: string}[]
}

export const selectOptions: selectOptionsValue[] = [
  {
    label: 'Períodos actuales',
    options: [
      { name: 'Hoy', id: 'today', },
      { name: 'Esta semana', id: 'current_week', },
      { name: 'Este mes', id: 'current_month', }
    ],
  },
  {
    label: 'Períodos anteriores',
    options: [
      { name: 'Ayer', id: 'yesterday', },
      { name: 'Semana pasada', id: 'last_week', },
      { name: 'Mes pasado', id: 'last_month', }
    ],
  },
  {
    label: 'Períodos específicos',
    options: [
      { name: 'Últimos 7 días', id: 'l7', },
      { name: 'Últimos 14 días', id: 'l14', },
      { name: 'Últimos 28 días', id: 'l28', },
      { name: 'Últimos 30 días', id: 'l30', },
      { name: 'Últimos 60 días', id: 'l60', },
      { name: 'Últimos 90 días', id: 'l90', }
    ],
  },
  {
    label: 'Personalizado',
    options: [
      { name: 'Rango de fechas', id: 'custom', }
    ],
  }
];

export const formatDay = 'DD.MM.YY';
export const formatMonth = 'MMM.YY';
export const dataValues2 = [{ total_cents: 459115, total_currency: 'VND', date: '2021-02-02', },
  { total_cents: 57556, total_currency: 'CAD', date: '2021-02-03', },
  { total_cents: 415515, total_currency: 'EGP', date: '2021-02-04', },
  { total_cents: 63403, total_currency: 'CNY', date: '2021-02-05', },
  { total_cents: 123846, total_currency: 'NPR', date: '2021-02-06', },
  { total_cents: 202876, total_currency: 'PHP', date: '2021-02-07', },
  { total_cents: 59960, total_currency: 'ALL', date: '2021-02-08', }];
export const dataValues = [{ id: 1, total_cents: 46593, date: '2021-11-29', },
  { id: 2, total_cents: 6551, date: '2021-11-30', },
  { id: 3, total_cents: 4810, date: '2021-12-01', },
  { id: 4, total_cents: 69949, date: '2021-12-02', },
  { id: 5, total_cents: 57466, date: '2021-12-03', },
  { id: 6, total_cents: 16725, date: '2021-12-04', },
  { id: 7, total_cents: 56683, date: '2021-12-05', },
  { id: 8, total_cents: 5652, date: '2021-12-06', },
  { id: 9, total_cents: 32705, date: '2021-12-07', },
  { id: 10, total_cents: 49718, date: '2021-12-08', },
  { id: 11, total_cents: 17053, date: '2021-12-09', },
  { id: 12, total_cents: 48852, date: '2021-12-10', },
  { id: 13, total_cents: 27462, date: '2021-12-11', },
  { id: 14, total_cents: 40197, date: '2021-12-12', },
  { id: 15, total_cents: 3776, date: '2021-12-13', },
  { id: 16, total_cents: 8557, date: '2021-12-14', },
  { id: 17, total_cents: 21387, date: '2021-12-15', },
  { id: 18, total_cents: 59692, date: '2021-12-16', },
  { id: 19, total_cents: 27417, date: '2021-12-17', },
  { id: 20, total_cents: 77640, date: '2021-12-18', },
  { id: 21, total_cents: 32805, date: '2021-12-19', },
  { id: 22, total_cents: 23846, date: '2021-12-20', },
  { id: 23, total_cents: 55123, date: '2021-12-21', },
  { id: 24, total_cents: 35270, date: '2021-12-22', },
  { id: 25, total_cents: 45810, date: '2021-12-23', },
  { id: 26, total_cents: 52952, date: '2021-12-24', },
  { id: 27, total_cents: 42862, date: '2021-12-25', },
  { id: 28, total_cents: 76780, date: '2021-12-26', },
  { id: 29, total_cents: 62270, date: '2021-12-27', },
  { id: 30, total_cents: 21684, date: '2021-12-28', },
  { id: 31, total_cents: 7086, date: '2021-12-29', },
  { id: 32, total_cents: 3746, date: '2021-12-30', },
  { id: 33, total_cents: 57059, date: '2021-12-31', },
  { id: 34, total_cents: 54525, date: '2022-01-01', },
  { id: 35, total_cents: 47796, date: '2022-01-02', },
  { id: 36, total_cents: 66663, date: '2022-01-03', },
  { id: 37, total_cents: 1343, date: '2022-01-04', },
  { id: 38, total_cents: 34957, date: '2022-01-05', },
  { id: 39, total_cents: 71603, date: '2022-01-06', },
  { id: 40, total_cents: 21777, date: '2022-01-07', },
  { id: 41, total_cents: 51942, date: '2022-01-08', },
  { id: 42, total_cents: 50201, date: '2022-01-09', },
  { id: 43, total_cents: 53275, date: '2022-01-10', },
  { id: 44, total_cents: 9835, date: '2022-01-11', },
  { id: 45, total_cents: 74467, date: '2022-01-12', },
  { id: 46, total_cents: 69044, date: '2022-01-13', },
  { id: 47, total_cents: 18529, date: '2022-01-14', },
  { id: 48, total_cents: 76506, date: '2022-01-15', },
  { id: 49, total_cents: 13479, date: '2022-01-16', },
  { id: 50, total_cents: 44742, date: '2022-01-17', },
  { id: 51, total_cents: 45944, date: '2022-01-18', },
  { id: 52, total_cents: 47562, date: '2022-01-19', },
  { id: 53, total_cents: 40948, date: '2022-01-20', },
  { id: 54, total_cents: 45099, date: '2022-01-21', },
  { id: 55, total_cents: 67927, date: '2022-01-22', },
  { id: 56, total_cents: 33740, date: '2022-01-23', },
  { id: 57, total_cents: 57934, date: '2022-01-24', },
  { id: 58, total_cents: 32695, date: '2022-01-25', },
  { id: 59, total_cents: 32349, date: '2022-01-26', },
  { id: 60, total_cents: 79805, date: '2022-01-27', },
  { id: 61, total_cents: 79951, date: '2022-01-28', },
  { id: 62, total_cents: 77627, date: '2022-01-29', },
  { id: 63, total_cents: 1646, date: '2022-01-30', },
  { id: 64, total_cents: 16298, date: '2022-01-31', },
  { id: 65, total_cents: 5334, date: '2022-02-01', },
  { id: 66, total_cents: 56604, date: '2022-02-02', },
  { id: 67, total_cents: 56355, date: '2022-02-03', },
  { id: 68, total_cents: 2702, date: '2022-02-04', },
  { id: 69, total_cents: 65660, date: '2022-02-05', },
  { id: 70, total_cents: 67154, date: '2022-02-06', },
  { id: 71, total_cents: 34120, date: '2022-02-07', },
  { id: 72, total_cents: 52218, date: '2022-02-08', },
  { id: 73, total_cents: 45088, date: '2022-02-09', },
  { id: 74, total_cents: 74660, date: '2022-02-10', },
  { id: 75, total_cents: 34403, date: '2022-02-11', },
  { id: 76, total_cents: 78467, date: '2022-02-12', },
  { id: 77, total_cents: 6947, date: '2022-02-13', },
  { id: 78, total_cents: 76456, date: '2022-02-14', },
  { id: 79, total_cents: 52260, date: '2022-02-15', },
  { id: 80, total_cents: 2109, date: '2022-02-16', },
  { id: 81, total_cents: 8464, date: '2022-02-17', },
  { id: 82, total_cents: 49418, date: '2022-02-18', },
  { id: 83, total_cents: 59710, date: '2022-02-19', },
  { id: 84, total_cents: 67820, date: '2022-02-20', },
  { id: 85, total_cents: 70833, date: '2022-02-21', },
  { id: 86, total_cents: 69071, date: '2022-02-22', },
  { id: 87, total_cents: 73069, date: '2022-02-23', },
  { id: 88, total_cents: 54605, date: '2022-02-24', },
  { id: 89, total_cents: 70981, date: '2022-02-25', },
  { id: 90, total_cents: 56387, date: '2022-02-26', },
  { id: 91, total_cents: 47384, date: '2022-02-27', },
  { id: 92, total_cents: 72113, date: '2022-02-28', },
  { id: 93, total_cents: 49571, date: '2022-03-01', },
  { id: 94, total_cents: 42214, date: '2022-03-02', },
  { id: 95, total_cents: 1349, date: '2022-03-03', },
  { id: 96, total_cents: 41129, date: '2022-03-04', },
  { id: 97, total_cents: 62904, date: '2022-03-05', },
  { id: 98, total_cents: 31615, date: '2022-03-06', },
  { id: 99, total_cents: 73858, date: '2022-03-07', },
  { id: 100, total_cents: 64701, date: '2022-03-08', },
  { id: 101, total_cents: 12189, date: '2022-03-09', },
  { id: 102, total_cents: 12426, date: '2022-03-10', },
  { id: 103, total_cents: 18578, date: '2022-03-11', },
  { id: 104, total_cents: 29745, date: '2022-03-12', },
  { id: 105, total_cents: 64069, date: '2022-03-13', },
  { id: 106, total_cents: 31957, date: '2022-03-14', },
  { id: 107, total_cents: 41972, date: '2022-03-15', },
  { id: 108, total_cents: 40651, date: '2022-03-16', },
  { id: 109, total_cents: 17893, date: '2022-03-17', },
  { id: 110, total_cents: 66714, date: '2022-03-18', },
  { id: 111, total_cents: 73306, date: '2022-03-19', },
  { id: 112, total_cents: 59320, date: '2022-03-20', },
  { id: 113, total_cents: 42235, date: '2022-03-21', },
  { id: 114, total_cents: 42762, date: '2022-03-22', },
  { id: 115, total_cents: 72125, date: '2022-03-23', },
  { id: 116, total_cents: 66624, date: '2022-03-24', },
  { id: 117, total_cents: 9382, date: '2022-03-25', },
  { id: 118, total_cents: 51031, date: '2022-03-26', },
  { id: 119, total_cents: 72659, date: '2022-03-27', },
  { id: 120, total_cents: 59116, date: '2022-03-28', }];
