




















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import flatPicker from '@/components/Reusable/FlatPicker.vue';
import { CommerceDataPagination, IdName } from '@/store/interfaces/commerce';
import { Action, Getter } from 'vuex-class';
import orderKindType from '@/store/types/orderKinds';
import { OrderKindParams } from '@/store/interfaces/orderKinds';
import { allowAny, responseDataPagination } from '@/store/interfaces/generic';
import { VuexFunction } from '@/ts/axios';

type nameValue = {
  name: string;
  value: string;
};

type nameId = {
  name: string;
  id: string;
};

@Component({
  name: 'filterTransactions',
  components: { flatPicker, },
})
export default class filterTransactions extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  commerces!: CommerceDataPagination;

  @Prop({
    required: true,
    type: Object,
  })
  branches!: responseDataPagination<IdName>;

  commerce_id = '';
  branch_id = '';
  kind = '';
  status = '';
  amount1 = '';
  amount2 = '';
  amountType = '';
  datesRange = '';
  cleanInputs = false;
  statusOption: nameId[] = [
    {
      name: 'Exitosos',
      id: 'success',
    },
    {
      name: 'Pendientes',
      id: 'pending',
    },
    {
      name: 'Fallidas',
      id: 'failure',
    },
    {
      name: 'Rechazadas',
      id: 'rejected',
    },
    {
      name: 'Canceladas',
      id: 'cancelled',
    }
  ];

  amountFilter: nameValue[] = [
    {
      name: 'Mayor o igual que',
      value: 'greaterThan',
    },
    {
      name: 'Menor o igual que',
      value: 'lessThan',
    },
    {
      name: 'Rango',
      value: 'between',
    }
  ];

  config = {
    mode: 'range',
    wrap: true, // set wrap to true only when using 'input-group'
    altFormat: 'M j, Y',
    altInput: true,
    dateFormat: 'Y-m-d',
  };

  async mounted(): Promise<void> {
    this.orderKindList();
  }

  @Watch('amountType')
  onAmountTypeChange(amount: string): void {
    if (amount === '') {
      this.amount1 = '';
      this.amount2 = '';
    }
  }

  @Watch('commerce_id')
  onCommerceIdChange(commerce_id: string): void {
    if (commerce_id) this.$emit('commerceId', commerce_id);
  }

  search(): void {
    const queryString: allowAny = [];
    queryString.commerce_id = this.commerce_id;
    queryString.branch_id = this.branch_id;
    queryString.kind = this.kind;
    queryString.status = this.status;
    if (this.datesRange) {
      const datesRanges = this.datesRange.split(' ');
      queryString.created_after = datesRanges[0];
      queryString.created_before = datesRanges[2] ? datesRanges[2] : datesRanges[0];
    }

    if (this.amountType === 'greaterThan') {
      queryString.subtotal_cents_gte = Number(this.amount1) * 100;
    }
    if (this.amountType === 'lessThan') {
      queryString.subtotal_cents_lte = Number(this.amount1) * 100;
    }

    if (this.amountType === 'between') {
      queryString.subtotal_cents_lte = Number(this.amount1) * 100;
      queryString.subtotal_cents_gte = Number(this.amount2) * 100;
    }

    let filters = '';
    Object.entries(queryString).forEach(([key, value]: allowAny) => {
      if (
        value &&
        !key.includes('created') &&
        !key.includes('subtotal_cents')
      ) {
        filters += `&${key}_eq=${value}`;
      } else if (key.includes('created') || key.includes('subtotal_cents')) {
        filters += `&${key}=${value}`;
      }
    });
    this.$root.$emit('bv::toggle::collapse', 'filter-transactions');
    this.$emit('search', filters);
  }

  clean(): void {
    this.cleanInputs = true;
    this.commerce_id = '';
    this.branch_id = '';
    this.kind = '';
    this.status = '';
    this.amount1 = '';
    this.amount2 = '';
    this.amountType = '';
    this.datesRange = '';
    this.cleanInputs = false;
    this.$emit('search', '');
  }

  @Action(`orderKindModule/${orderKindType.actions.ORDER_KINDS}`)
  orderKindList!: VuexFunction;

  @Getter(`orderKindModule/${orderKindType.getters.GET_ORDER_KINDS}`)
  getOrderKindList!: responseDataPagination<OrderKindParams>;
}
