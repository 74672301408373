
































































































































import Vue, { PropType } from 'vue';
import SidebarModal from '@/components/Reusable/SidebarModal.vue';
import commerces from '@/store/types/commerces';
import { IdName } from '@/store/interfaces/commerce';
import { responseDataPagination } from '@/store/interfaces/generic';
import datetime from './DateTime.vue';
import transactions from '@/store/types/transactions';
import branches from '@/store/types/branches';
import dayjs from 'dayjs';
import { TeipNotifications } from '@/util/teipNotifications';
import { ValidationProvider } from 'vee-validate';
import { tNewDinamicPayment } from '@/store/interfaces/dinamicPayments';

export default Vue.extend({
  name: 'NewPaymentSidebarModal',
  components: {
    SidebarModal,
    datetime,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
    payment: {
      type: Object as PropType<tNewDinamicPayment>,
      required: false,
      default: () => {
        return {
          id: '',
          code: '',
          branch_id: '',
          subtotal_cents: 0,
          description: '',
          start_at: '',
          end_at: '',
          occurrences_count: 0,
        };
      },
    },
  },
  data() {
    return {
      commerce_id: '',
      branch_id: '',
      savingNewPayment: false,
    };
  },
  mounted(): void {
    this.$store.dispatch(`commercesModule/${commerces.actions.COMMERCE_LIST}`);
    this.payment.code = this.generateCode();
  },
  watch: {
    commerce_id: {
      handler(id: string): void {
        this.$store.dispatch(
          `branchesModule/${branches.actions.BRANCHES_LIST}`,
          {
            commerce_id: id,
            params: 'per_page=100',
          }
        );
      },
      immediate: false,
    },
    branch_id: {
      handler(id: string): void {
        const branch = this.getAllBranchesList.data.filter(
          branch => branch.id === id
        )[0];

        this.getPayment.branch_id = id;
        if (branch) {
          const array = branch.name.split(' ');

          let output = '';

          for (let i = 0; i < array.length; i++) {
            const word = array[i];
            const firstChar = word.split('')[0];
            output += firstChar;
          }

          this.payment.code = this.generateCode(
            output.replace(/[^a-zA-Z0-9 ]/g, '')
          );
        }
      },
      immediate: false,
    },
  },
  methods: {
    currentDateInISO8601Format(): string {
      const fecha = dayjs(new Date());
      // Formato ISO 8601
      return fecha.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    saveDinamicPayments(): void {
      this.savingNewPayment = true;
      this.$store
        .dispatch(
          `transactionsModule/${transactions.actions.SAVE_DINAMIC_PAYMENTS}`,
          {
            ...this.getPayment,
            subtotal_cents: this.getPayment.subtotal_cents * 100,
          }
        )
        .then(() => {
          this.saveHasBeenSuccesfully();
          (this.$refs.formNewPayment as InstanceType<
            typeof ValidationProvider
          >).reset();
        })
        .catch(() => {
          const tipNotification = new TeipNotifications();
          tipNotification.warning('Error al guardar el pago dinámico');
        })
        .finally(() => {
          this.savingNewPayment = false;
        });
    },
    actionsDinamicPayments(): void {
      if (this.isUpdate) {
        this.updateDinamicPayments();
      } else {
        this.saveDinamicPayments();
      }
    },
    updateDinamicPayments() {
      this.savingNewPayment = true;

      this.$store
        .dispatch(
          `transactionsModule/${transactions.actions.UPDATE_DINAMIC_PAYMENTS}`,
          {
            data: {
              ...this.getPayment,
              subtotal_cents: this.getPayment.subtotal_cents * 100,
            },
            id: this.payment.id,
          }
        )
        .then(() => {
          this.saveHasBeenSuccesfully();
          (this.$refs.formNewPayment as InstanceType<
            typeof ValidationProvider
          >).reset();
        })
        .catch(() => {
          const tipNotification = new TeipNotifications();
          tipNotification.warning('Error al actualizar el pago dinámico');
        })
        .finally(() => {
          this.savingNewPayment = false;
        });
    },
    generateCode(prefix = 'TP'): string {
      return `${prefix}-${Math.floor(Math.random() * 10000)}`;
    },
    saveHasBeenSuccesfully(): void {
      this.$emit('update:show', false);
      this.$emit('update-list', true);
      this.getPayment.code = this.generateCode();
      this.getPayment.branch_id = '';
      this.getPayment.subtotal_cents = 0;
      this.getPayment.description = '';
      this.getPayment.start_at = '';
      this.getPayment.end_at = '';
      this.getPayment.occurrences_count = 0;
    },
  },
  computed: {
    getAllCommerceList(): responseDataPagination<IdName> {
      return this.$store.getters[
        `commercesModule/${commerces.getters.GET_COMMERCE_LIST}`
      ];
    },
    getAllBranchesList(): responseDataPagination<IdName> {
      return this.$store.getters[
        `branchesModule/${branches.getters.GET_BRANCHES_LIST}`
      ];
    },
    showSidebarBar: {
      get(): boolean {
        return this.show;
      },
      set(v: boolean): void {
        this.payment.code = this.generateCode();
        this.$emit('update:show', v);
      },
    },
    getPayment: {
      get(): tNewDinamicPayment {
        return this.payment;
      },
      set(v: tNewDinamicPayment): void {
        this.$emit('update:payment', v);
      },
    },
  },
});
