





























import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import authType from '@/store/types/auth';
import { TeipNotifications } from '@/util/teipNotifications';
import { VuexFunction } from '@/ts/axios';
import backToLogin from '@/components/Reusable/backToLogin.vue';

@Component({
  components: {
    backToLogin,
  },
})
export default class SendPasswordInstructions extends Vue {
  user = {
    email: '',
  };

  loading = false;

  async sendInstructions(): Promise<void> {
    this.loading = true;
    const teipNotification = new TeipNotifications();
    try {
      await this.sendPasswordInstructions(this.user);
      teipNotification.success(
        'Se ha enviado un correo con instrucciones para recuperar su contraseña'
      );
      await this.$router.push({
        name: 'Login',
      });
    } catch (err) {
      this.loading = false;
      teipNotification.warning('Correo inválido');
    }
  }

  // VUEX
  @Action(`authModule/${authType.actions.SEND_PASSWORD_INSTRUCTIONS}`)
  sendPasswordInstructions!: VuexFunction;
}
