






































































































import {
  Component,
  Prop,
  Watch,
  Vue,
  Ref
} from 'vue-property-decorator';
// vuex
import { Action, Getter } from 'vuex-class';
import { CompanyUserParams, User } from '@/store/interfaces/companyUser';
import root from '@/store/types/root';
import companyUser from '@/store/types/companyUser';
import permissionGroup from '@/store/types/permissionGroup';
import { AxiosResponse } from 'axios';
import { TeipNotifications } from '@/util/teipNotifications';
import { PermissionGroupDataPagination } from '@/store/interfaces/PermissionGroup';
import { UserCompanyParams } from '@/store/interfaces/userCompany';
import { VuexFunction } from '@/ts/axios';
import { ValidationProvider } from 'vee-validate';
import { allowAny } from '@/store/interfaces/generic';

type user = {
  first_name: '',
  last_name: '',
  id: '',
  permission_group_id: '',
}
type initData = {
  user: user
}
type data = {
  disabledButton: boolean,
  disabled: boolean,
  messageButton: string,
  update: boolean,
  email: string,
  user: user,
  positionContactKind: number,
  loading: boolean,
  contactKind: [],
  userFound: undefined,
}

type BasicInfoUser = {
  first_name: string;
  last_name: string;
};
interface formValidatorContactModal extends InstanceType<typeof ValidationProvider> {
  fields: {
    email: {
      valid: boolean
    };
  }
}

@Component({
  name: 'AddTeipBizUser',
})
export default class ContactModal extends Vue {
  @Ref('formValidatorContactModal') readonly formValidatorContactModal!: formValidatorContactModal

  @Ref('userPermission') readonly userPermission!: allowAny;

  @Prop({
    type: Object,
    required: true,
  })
  userUpdate!: UserCompanyParams;

  @Prop({
    type: Object,
    required: true,
  })
  errors!: string[];

  @Prop({
    type: Boolean,
    required: true,
  })
  saveIsSuccess!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  viewModal!: boolean;

  get syncedViewModal(): boolean {
    return this.$props.viewModal;
  }

  set syncedViewModal(val: boolean) {
    if (this.$data.update) this.cleanModel();
    this.$emit('update:viewModal', val);
  }

  @Watch('errors', { immediate: true, deep: true, })
  onRecievedErrors(errorsToServer: string[]): void {
    if (this.formValidatorContactModal) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors: any = {};
      Object.entries(errorsToServer).forEach(([key, value]) => {
        const inputKeyName = key.split('.')[1];
        if (inputKeyName === 'email') {
          errors.email = value;
        } else {
          errors[key] = value;
        }
      });
      this.formValidatorContactModal.setErrors(errors);
    }
  }

  @Watch('saveIsSuccess', { deep: true, })
  onSuccessIsTrue(success: boolean): void {
    if (success) this.cleanModel();
  }

  @Watch('userUpdate', { immediate: true, deep: true, })
  onUserUpdateChange(val: CompanyUserParams): void {
    if (Object.keys(val).length > 0) {
      this.$data.update = true;
      this.$data.messageButton = 'Actualizar usuario';
      this.$data.email = val.user?.email;
      this.$data.user.first_name = val.user?.profile?.first_name;
      this.$data.user.last_name = val.user?.profile?.last_name;
      this.$data.user.permission_group_id = val.permission_group_id;
      this.userPermission.$data.select = val.permission_group_id;
    } else {
      this.$data.update = false;
      this.$data.messageButton = 'Guardar usuario';
    }
  }

  cleanModel(): void {
    if (this.formValidatorContactModal) {
      this.formValidatorContactModal.reset();
    }
    const values = {
      first_name: '',
      last_name: '',
      permission_group_id: '',
    };
    if (this.userPermission) {
      this.userPermission.$data.select = '';
    }
    this.$data.userFound = undefined;
    this.$data.email = '';
    this.$data.user = values;
  }

  async searchUser(): Promise<void> {
    const isValid = this.formValidatorContactModal.fields.email.valid;
    if (isValid) {
      this.CompanyUserList(`email_eq=${this.$data.email}`)
        .then((res: AxiosResponse) => {
          if (res.data.data.length > 0) {
            const user: User = res.data.data[0];
            this.$data.user.id = user.id;
            this.$data.email = user.email;
            this.$data.user.first_name = user.profile?.first_name;
            this.$data.user.last_name = user.profile?.last_name;
            this.$data.userFound = true;
            this.$data.disabled = true;
          } else {
            this.$data.user = this.initData().user;
            this.$data.userFound = false;
            this.$data.disabled = false;
            this.formValidatorContactModal.reset();
          }
        })
        .catch(() => {
          this.$data.disabled = true;
          const notification = new TeipNotifications();
          notification.warning('Algo salió mal al buscar tu contacto :(');
        });
    }
  }

  initData(): initData {
    return {
      user: {
        first_name: '',
        last_name: '',
        id: '',
        permission_group_id: '',
      },
    };
  }

  data(): data {
    return {
      disabledButton: true,
      disabled: true,
      messageButton: 'Guardar usuario',
      update: false,
      email: '',
      user: this.initData().user,
      positionContactKind: 1,
      loading: true,
      contactKind: [],
      userFound: undefined,
    };
  }

  contactAction(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const user: any = {
      user: {
        email: this.$data.email,
        profile: {
          first_name: this.$data.user.first_name,
          last_name: this.$data.user.last_name,
        },
      },
      permission_group_id: this.$data.user.permission_group_id,
    };
    this.$data.user.id && (user.id = this.$data.user.id);
    let contactInfo = {
      update: false,
      user,
    };
    if (this.$data.update) {
      contactInfo = {
        update: true,
        user,
      };
    }
    this.$data.disabled = true;
    this.$data.disabledButton = true;
    this.$emit('contactInfo', contactInfo);
  }

  created(): void {
    this.$data.loading = false;
  }

  cancel(): void {
    this.cleanModel();
    this.syncedViewModal = false;
    this.$data.disabled = true;
    this.$data.disabledButton = true;
  }

  @Watch('user', { deep: true, })
  onUserCange(val: BasicInfoUser): void {
    if (val.first_name && val.last_name) {
      this.$data.disabledButton = false;
    } else {
      this.$data.disabledButton = true;
    }
  }

  @Getter(root.getters.GET_START_PROCESS) getProcess!: boolean;
  @Action(`companyUserModule/${companyUser.actions.SEARCH_USER}`)
  CompanyUserList!: VuexFunction;

  @Getter(
    `permissionGroupModule/${permissionGroup.getters.GET_PERMISSIONS_GROUP_LIST}`
  )
  permissions?: PermissionGroupDataPagination;
}
