
















































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import authType from '@/store/types/auth';
import { TeipNotifications } from '@/util/teipNotifications';
import { AxiosError } from 'axios';
import { VuexFunction } from '@/ts/axios';

@Component
export default class extends Vue {
  user = {
    email: '',
    password: '',
  };

  loading = false;
  withCommerceQuery = false;
  cn = '';

  mounted(): void {
    if (this.$route.query.cn) {
      this.withCommerceQuery = true;
      this.cn = this.$route.query.cn as string;
    }
  }

  checkIfHavePermissionToSalesReport(
    permissions: string[],
    permissionSlug: string
  ): boolean {
    return permissions.some(
      (permission: string) => permission === permissionSlug
    );
  }

  async login(): Promise<void> {
    this.loading = true;
    this.authenticate(this.user)
      .then(() => {
        if (
          this.checkIfHavePermissionToSalesReport(
            this.permissions,
            'statistics_company_sales_summaries'
          )
        ) {
          this.$router.push({
            name: 'salesReport',
          });
        } else {
          this.$router.push({
            name: 'home',
          });
        }
      })
      .catch((err: AxiosError) => {
        const teipNotification = new TeipNotifications();
        if (err.response?.data.type === 'locked_account_by_attempts') {
          teipNotification.warning('Cuenta Bloqueada');
        } else {
          teipNotification.warning('Correo o Contraseña inválida.');
        }
        this.loading = false;
      });
  }

  // VUEX
  @Action(`authModule/${authType.actions.AUTHORIZE}`)
  authenticate!: VuexFunction;

  @Getter(`authModule/${authType.getters.PERMISSIONS}`) permissions!: string[];
}
