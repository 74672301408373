










import moment from 'moment';
import flatPicker from 'vue-flatpickr-component';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import 'flatpickr/dist/flatpickr.css';
import { Component, Vue, PropSync, Prop, Ref } from 'vue-property-decorator';
import { CustomLocale } from 'flatpickr/dist/types/locale';
moment.locale('es');

type data = {
  config: {
    locale: CustomLocale
  }
}
type flatpickrConfig = {
  minDate: string,
}

@Component({
  name: 'FlatPicker',
  components: {
    flatPicker,
  },
})
export default class FlatPicker extends Vue {
  data(): data {
    return {
      config: {
        locale: Spanish,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref('fp') fp!: any;
  @Prop({
    required: false,
    default: 'Fecha',
    type: String,
  })
  placeholder!: string;

  @Prop({
    required: false,
    type: String,
  })
  value!: string;

  @PropSync('conf', { required: false, type: Object, default: () => {}, })
    syncedConf!: flatpickrConfig

  get inputValue(): string {
    return this.$props.value;
  }

  set inputValue(val: string) {
    this.$emit('input', val);
  }
}
