import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  CompanyUserState,
  CompanyUserCollectionResponse,
  CompanyUserParams
} from '@/store/interfaces/companyUser';
import userType from '@/store/types/companyUser';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../interfaces/rootState';

const namespaced = true;

const initialState = (): CompanyUserState => {
  return {
    companyUsers: {
      data: [],
      pagination: {},
    },
    companyUser: {
      id: '',
      name: '',
      permission_group_id: '',
      company_id: '',
      user_id: '',
      user: {
        id: '',
        email: '',
        profile: {
          id: '',
          first_name: '',
          last_name: '',
        },
      },
      company_users: [],
    },
  };
};

const state: CompanyUserState = initialState();

const getters: GetterTree<CompanyUserState, RootState> = {
  [userType.getters.GET_COMPANY_USERS]: state => {
    return state.companyUsers;
  },
  [userType.getters.GET_SINGLE_COMPANY_USER]: state => {
    return state.companyUser;
  },
};
const mutations: MutationTree<CompanyUserState> = {
  reset: state => {
    const initialStateFields = initialState();
    Object.keys(initialStateFields).forEach(key => {
      state[key] = initialStateFields[key];
    });
  },
  [userType.mutations.SET_COMPANY_USERS]: (
    state,
    users: CompanyUserCollectionResponse
  ) => {
    const usersFormated: Array<CompanyUserParams> = [];
    users.data.map((user: CompanyUserParams) => {
      const userFormate = user;
      userFormate.name = `${user.user.profile?.first_name} ${user.user?.profile?.last_name}`;

      usersFormated.push(userFormate);
    });
    state.companyUsers.data = usersFormated;
    state.companyUsers.pagination = users.meta.pagination;
  },
  [userType.mutations.SET_SINGLE_COMPANY_USER]: (state, user: CompanyUserParams) => {
    state.companyUser = user;
  },
};
const actions: ActionTree<CompanyUserState, RootState> = {
  [userType.actions.COMPANY_USERS_LIST]: ({ commit, }, queryParams = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/company_users?${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_COMPANY_USERS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.SEARCH_USER]: (_, queryParams = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/users?${queryParams}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.SINGLE_COMPANY_USER]: ({ commit, }, user_id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/company_users/${user_id}`)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.UPDATE_COMPANY_USER]: ({ commit, }, staffUser) => {
    const { user_id, data, } = staffUser;
    const dataToSend = {
      data,
    };
    return new Promise((resolve, reject) => {
      http
        .put(`companies/company/company_users/${user_id}`, dataToSend)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.SAVE_COMPANY_USER]: ({ commit, }, data) => {
    return new Promise((resolve, reject) => {
      http
        .post('companies/company/company_users', { data, })
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.DELETE_COMPANY_USER]: (_, id) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`companies/company/company_users/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
