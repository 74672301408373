































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import flatPicker from '@/components/Reusable/FlatPicker.vue';
import { IdName } from '@/store/interfaces/commerce';

@Component({
  name: 'ScanToPayForm',
  components: { flatPicker, },
})
export default class ScanToPayForm extends Vue {
  @Prop({
    required: true,
    type: Array,
  })
  commerces!: IdName[];

  @Prop({
    required: true,
    type: Array,
  })
  branches!: IdName[];

  @Prop({
    required: true,
    type: Boolean,
  })
  cleanInputs!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '4',
  })
  md!: string;

  commerce_id = ''
  branch_id = ''

  @Watch('commerce_id')
  onCommerceIdChange(commerce_id: string): void {
    this.$emit('commerceId', commerce_id);
    this.branch_id = '';
  }

  @Watch('branch_id')
  onBranchIdChange(branch_id: string): void {
    this.$emit('branchId', branch_id);
  }
}
