






































































































































import {
  transactionInfoToShow,
  TransactionParams
} from '@/store/interfaces/transactions';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { status } from '@/util/transformString/statusDecorator';
import noImage from '@/assets/images/unknow-user.png';
import CreditCard from '@/components/Reusable/CreditCard.vue';
import Status from '@/components/Reusable/Status.vue';
import HeaderModal from './headerModal.vue';

@Component({
  name: 'TransactionInfo',
  components: { CreditCard, HeaderModal, Status, },
})
export default class TransactionInfo extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  transaction!: TransactionParams | transactionInfoToShow;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  isDownloadVoucher!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  isSendingEmail!: boolean;

  public status = status;

  public noImage = noImage;

  get total(): number {
    if (
      'total_cents' in this.transaction &&
      this.transaction.total_cents != null
    ) {
      return this.transaction.total_cents;
    }

    return (
      this.transaction.subtotal_cents +
      this.transaction.tip_cents -
      this.transaction.discount_cents
    );
  }

  get statusColor(): string {
    let color = 'text-success';
    if (
      this.transaction.status === 'pending' ||
      this.transaction.status === 'failure'
    ) {
      color = 'text-warning';
    } else if (
      this.transaction.status === 'cancelled' ||
      this.transaction.status === 'rejected'
    ) {
      color = 'text-danger';
    }
    return color;
  }

  get isSuccess(): boolean {
    return this.transaction.status === 'success';
  }

  get avatar(): string {
    if (
      'user' in this.transaction &&
      this.transaction.user.profile.avatar_url != null
    ) {
      return this.transaction.user.profile.avatar_url;
    } else if (
      'user_avatar_url' in this.transaction &&
      this.transaction.user_avatar_url != null
    ) {
      return this.transaction.user_avatar_url;
    }
    return noImage;
  }

  get fullName(): string {
    return 'user' in this.transaction
      ? `${this.transaction.user.profile.first_name} ${this.transaction.user.profile.last_name}`
      : this.transaction.user_name;
  }

  get handle(): string {
    if ('user' in this.transaction) {
      return this.transaction.user.handle ? this.transaction.user.handle : '';
    }

    if ('user_handle' in this.transaction) {
      return this.transaction.user_handle ? this.transaction.user_handle : '';
    }

    return '';
  }

  downloadVoucher(): void {
    this.$emit('downloadVoucher', this.transaction.id);
  }

  resentEmail(): void {
    this.$emit('resentEmail', this.transaction.id);
  }

  get isCreditCardOrBot(): boolean {
    return (
      this.transaction.distribution === 'credit_card' ||
      this.transaction.distribution === 'both'
    );
  }

  get mustShowBalance(): boolean {
    return (
      this.transaction.distribution === 'cash' ||
      this.transaction.distribution === 'both'
    );
  }

  get isPaymentLink(): boolean {
    if ('order_kind' in this.transaction && this.transaction.order_kind) {
      return this.transaction.order_kind === 'payment_link';
    }
    return false;
  }

  get isCash(): boolean {
    return this.transaction.distribution === 'cash';
  }

  get authorization_number(): string {
    return 'authorization_number' in this.transaction &&
      this.transaction.authorization_number
      ? this.transaction.authorization_number
      : '';
  }

  get reference_number(): string {
    return 'reference_number' in this.transaction &&
      this.transaction.reference_number
      ? this.transaction.reference_number
      : '';
  }

  get card_last_digits(): string {
    return 'card_last_digits' in this.transaction &&
      this.transaction.card_last_digits
      ? this.transaction.card_last_digits
      : '';
  }

  get card_network(): string {
    return 'card_network' in this.transaction && this.transaction.card_network
      ? this.transaction.card_network
      : '';
  }

  get cardExpiration(): string | number {
    const month =
      'expiration_month' in this.transaction &&
      this.transaction.expiration_month
        ? this.transaction.expiration_month
        : '';

    const year =
      'expiration_year' in this.transaction && this.transaction.expiration_year
        ? this.transaction.expiration_year
        : '';

    if (month === '' && year === '') return '';

    return `${month}/${year}`;
  }

  get dui(): string {
    if (
      'document_number' in this.transaction &&
      this.transaction.document_number
    ) {
      return this.transaction.document_number.replace(
        /(\d{8})(\d{1})/,
        '$1-$2'
      );
    }
    return '';
  }
}
