






































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { TeipNotifications } from '@/util/teipNotifications';
import { AxiosError } from 'axios';
import { ValidationProvider } from 'vee-validate';

type data = {
  title: string,
  sendingData: boolean,
  body: string,
  options: {name: string, }[]
}

@Component({
  name: 'SalesReportHome',
})
export default class SalesReportHome extends Vue {
  @Ref('formSupport') readonly formSupport!: InstanceType<typeof ValidationProvider>;
  data(): data {
    return {
      title: '',
      sendingData: false,
      body: '',
      options: [
        {
          name: 'Reportes de ventas',
        },
        {
          name: 'Historial de transacciones',
        },
        {
          name: 'Problemas con pagos',
        },
        {
          name: 'Error técnico',
        },
        {
          name: 'Información incorrecta',
        },
        {
          name: 'Otros',
        }
      ],
    };
  }

  get charactersLeft(): string {
    const char = this.$data.body.length;
    const limit = 300;

    return (limit - char) + ' / ' + limit + ' carácteres restantes';
  }

  firstFormSubmit(): void {
    const data = {
      body: this.$data.body,
      title: this.$data.title,
    };
    const notification = new TeipNotifications();

    this.$http.post('companies/company/supports', { data, })
      .then(() => {
        notification.success('Tu ticket ha sido recibido. Te daremos seguimiento cuanto antes. 🙌');
        this.clean();
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 422) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const error: any = [];
          Object.keys(err.response.data.error).includes('title') && (
            error['razón'] = err.response.data.error.title
          );
          Object.keys(err.response.data.error).includes('body') && (
            error.contenido = err.response.data.error.body
          );
          this.formSupport.setErrors(error);
        }
        notification.warning(
          'No se ha podido enviar :('
        );
      });
  }

  clean(): void {
    this.$data.title = '';
    this.$data.body = '';
    this.formSupport.reset();
  }
}
