




























import { Component, Vue } from 'vue-property-decorator';
import circleDashboard from '@/assets/images/screen-dashboard-circle.png';

type data = {
  circleDashboard: string,
  cn: string,
}
@Component({
  name: 'Landing',
})
export default class Landing extends Vue {
  data(): data {
    return {
      circleDashboard,
      cn: 'Empresa desconocida',
    };
  }

  mounted(): void {
    if (this.$route.query.cn) {
      this.$data.cn = this.$route.query.cn;
    }
  }

  goDashboard(): void {
    this.$router.push({
      name: 'home',
      query: { hasNewCompany: '1', },
    });
  }
}
