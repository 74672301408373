import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';
import commerceType from '@/store/types/commerces';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
import { CommerceState, CommerceWithMeta } from '@/store/interfaces/commerce';

const namespaced = true;
const initialState = (): CommerceState => {
  return {
    commerces: {
      data: [],
      pagination: {},
    },
    settings: {
      id: '',
      tip_percentage: 0,
      delivery_fee_cents: 0,
      delivery_fee_currency: '',
    },
  };
};

const state: CommerceState = initialState();
const getters: GetterTree<CommerceState, RootState> = {
  [commerceType.getters.GET_COMMERCE_LIST]: state => {
    return state.commerces;
  },
  [commerceType.getters.GET_COMMERCE_SETTINGS]: state => {
    return state.settings;
  },
};

const mutations: MutationTree<CommerceState> = {
  reset: state => {
    const initialStateFields = initialState();
    Object.keys(initialStateFields).forEach(key => {
      state[key] = initialStateFields[key];
    });
  },
  [commerceType.mutations.SET_COMMERCE_LIST]: (
    state,
    commerce: CommerceWithMeta
  ) => {
    state.commerces.data = commerce.data;
    state.commerces.pagination = commerce.meta.pagination;
  },
  [commerceType.mutations.SET_COMMERCE_SETTINGS]: (
    state,
    commerce
  ) => {
    state.settings = commerce.data;
  },
};
const actions: ActionTree<CommerceState, RootState> = {
  [commerceType.actions.COMMERCE_LIST]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('companies/company/commerces')
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_COMMERCE_LIST, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.COMMERCE_SETTINGS]: ({ commit, }, id: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/commerces/${id}/settings`)
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_COMMERCE_SETTINGS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
