import moment from 'moment-timezone';

moment.locale('es');

export default class DatesFormat {
  public formatDate (date: string | null | undefined): string {
    if (date) {
      return moment(date).format('ll');
    }
    return 'No disponible';
  }

  public formatDateTime (date: string | null | undefined): string {
    if (date) {
      return moment.utc(date).tz('America/El_Salvador').format('lll');
    }
    return 'No disponible';
  }

  public formatYear (date: string | null | undefined): string {
    if (date) {
      return moment.utc(date).tz('America/El_Salvador').format('MM-DD-YYYY');
    }
    return 'No disponible';
  }
}
