






















































































/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import logo from '@/assets/images/logo-white.svg';
import ReloadComponentMixin from '@/mixins/ReloadComponentMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { version } from '@/../package.json';

function hasElement(className: string) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className: string) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

@Component({
  name: 'Dashboardlayout',
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
})
export default class Dashboardlayout extends mixins(ReloadComponentMixin) {
  logo = logo;
  version = version;
  showVersion = true;

  initScrollbar(): void {
    const isWindows = navigator.platform.startsWith('Win');
    if (isWindows) {
      initScrollbar('sidenav');
    }
  }

  isOpenSidebar(isOpen: boolean): void {
    this.showVersion = isOpen;
  }

  mounted(): void {
    this.initScrollbar();
  }
}
