












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Select, Option, OptionGroup } from 'element-ui';

@Component({
  name: 'teipSelect',
  components: {
    'el-select': Select,
    'el-option': Option,
    'el-option-group': OptionGroup,
  },
})
export default class teipSelect extends Vue {
  @Prop({
    required: true,
    type: [Object, Array],
  })
  selectOptions?: [Array<string>];

  @Prop({
    required: true,
    type: String,
  })
  labelOption?: string;

  // Use this props when you want clean select
  @Prop({
    required: false,
    type: Boolean,
  })
  cleanSelect?: boolean;

  @Prop({
    required: false,
    type: String,
    default: 'id',
  })
  valueOption?: string;

  @Prop({
    required: false,
    type: String,
    default: 'Seleccione',
  })
  placeholder?: string;

  @Prop({
    type: [String, Array, Object],
    required: false,
  })
  rules?: [string, Array<string>];

  @Prop({
    required: true,
    type: String,
  })
  name?: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  disabled?: boolean;

  @Prop({
    required: false,
    type: String,
  })
  label?: string;

  @Prop({
    required: false,
    type: Number,
    default: 1,
  })
  defaultSelected?: number;

  @Prop({
    required: false,
    type: Number,
    default: 1,
  })
  defaultSelectedGroup?: number;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  defaultSelect?: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  clearable?: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  hasIcon?: boolean;

  @Prop({
    required: false,
    type: String,
    default: '',
  })
  icon?: string;

  @Prop({
    required: false,
    type: [String, Array, Number],
  })
  value!: [string, Array<string>, number];

  @Watch('defaultSelected', { immediate: true, deep: true, })
  onDefaultSelectChange(): void {
    this.setDefaultOption();
  }

  mounted(): void {
    this.setDefaultOption();
  }

  setDefaultOption(): void {
    if (this.$props.defaultSelect && this.$props.selectOptions.length > 0) {
      this.inputValue = this.$props.selectOptions[
        this.$props.defaultSelectedGroup - 1
      ].options[this.$props.defaultSelected - 1][this.$props.valueOption];
    }
  }

  clean(): void {
    if (this.$props.multiple) {
      this.inputValue = [];
    } else {
      this.inputValue = '';
    }
  }

  data(): { select: string } {
    return {
      select: '',
    };
  }

  get inputValue(): string | string[] | number {
    return this.$props.value;
  }

  set inputValue(val: string | string[] | number) {
    this.$emit('input', val);
  }

  @Watch('cleanSelect')
  oncleanSelectChange(val: boolean): void {
    val && this.clean();
  }
}
