




import Vue from 'vue';
import tDesktop from './tDesktop.vue';
// import tDesktop from './tDesktop.vue';

export default Vue.extend({
  components: { tDesktop, },
  // components: { tDesktop, },
  name: 'NavBar',
  data() {
    return {};
  },
  methods: {},
});
