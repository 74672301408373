























































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import authType from '@/store/types/auth';
import { TeipNotifications } from '@/util/teipNotifications';
import { VuexFunction } from '@/ts/axios';
import BackToLogin from '@/components/Reusable/backToLogin.vue';
import { allowAny } from 'teip-components';

@Component({
  name: 'ResetPassword',
  components: {
    BackToLogin,
  },
})
export default class ResetPassword extends Vue {
  data = {
    password: '',
    password_confirmation: '',
    code: '',
  };

  passwordRules =
    'required|min:8|max:20|regex:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@#$%^&*]).*$';

  passwordConfirmationRules =
    // eslint-disable-next-line max-len
    'required|min:8|max:20|regex:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@#$%^&*]).*$';

  loading = false;

  mounted(): void {
    this.data.code = this.$route.params.id;
  }

  async reset(): Promise<void> {
    this.loading = true;
    const teipNotification = new TeipNotifications();
    try {
      await this.resetPassword(this.data);
      teipNotification.success('Cambio de contraseña satisfactorio');
      await this.$router.push({
        name: 'Login',
      });
    } catch (err) {
      this.loading = false;

      const errors = (err as allowAny).response.data.error;

      if (typeof errors === 'object') {
        const errorsKey = Object.keys(errors);
        teipNotification.warning(`${errorsKey[0]}: ${errors[errorsKey[0]][0]}`);
      } else {
        teipNotification.warning(
          'Error al intentar cambiar la contraseña'
        );
      }
    }
  }

  // VUEX
  @Action(`authModule/${authType.actions.RESET_PASSWORD}`)
  resetPassword!: VuexFunction;
}
