


































import { Component, Prop, Vue } from 'vue-property-decorator';
import flatPicker from '@/components/Reusable/FlatPicker.vue';
import { CommerceDataPagination, IdName } from '@/store/interfaces/commerce';
import scanToPayForm from './scanToPayForm.vue';
import { responseDataPagination } from '@/store/interfaces/generic';

@Component({
  name: 'ScanToPayTransactions',
  components: { flatPicker, scanToPayForm, },
})
export default class ScanToPayTransactions extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  commerces!: CommerceDataPagination;

  @Prop({
    required: true,
    type: Object,
  })
  branches!: responseDataPagination<IdName>;

  filters = {
    commerce_id: '',
    branch_id: '',
  };

  cleanInputs = false;

  onCommerceIdChange(commerce_id: string): void {
    if (commerce_id) this.$emit('commerceId', commerce_id);
    this.filters.commerce_id = commerce_id;
  }

  onBranchIdChange(branch_id: string): void {
    this.filters.branch_id = branch_id;
  }

  search(): void {
    let query = '';
    Object.entries(this.filters).forEach(([key, value]) => {
      if (value) { query += `&${key}=${value}`; }
    });
    this.$emit('search', query);
    this.$root.$emit('bv::toggle::collapse', 'scan-to-pay-filter');
  }

  clean(): void {
    this.$emit('search', '');
    this.cleanInputs = true;
    this.filters.commerce_id = '';
    this.filters.branch_id = '';
  }
}
