/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { RouteConfig } from 'vue-router';

const SupportForm = () =>
  import(
    /* webpackChunkName: "SupportForm" */
    '@/views/Support/New.vue'
  );

const transactionHistoryRoute: RouteConfig = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: '/support',
      name: 'SupportForm',
      component: SupportForm,
      meta: {
        navbarType: 'light',
      },
    }
  ],
};
export default transactionHistoryRoute;
