/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import DashboardPlugin from '@/plugins/dashboardPlugin.js';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import NProgress from 'nprogress';
import '@/config/sentry';
import '@/util/filters';
import auth from './store/types/auth';
import '@/assets/sass/tailwind.scss';
// glue
require('glue-components/dist/glue-components.min.css');

NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
});

store.watch(
  (state: any) => state.startprocess,
  (newVal: number, oldVal: number) => {
    if (newVal === 0) return NProgress.done();
    if (oldVal === 0) return NProgress.start();
    NProgress.set(1.8 / Math.max(oldVal, newVal));
  }
);

Vue.use(DashboardPlugin);

if (store.getters[`authModule/${auth.getters.IS_AUTHENTICATED}`]) {
  store.dispatch(`authModule/${auth.actions.EXPIRES_IN}`);
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});
